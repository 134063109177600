import React, { useState } from "react";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Client, Invite, User } from "api/models/Client";
import { RoleTypes } from "helpers/enums";
import NewUserModal from "components/Modals/NewUser";
import { getInitials } from "helpers/users";
import UserCard from "./UserCard";
import { Snackbar } from "types/snackbar";

interface RelatedUsersProps {
  client: Client;
  setSnackbar: React.Dispatch<React.SetStateAction<Snackbar>>;
  handleDispatchClient?: () => void;
  disabled: boolean;
}

const RelatedUsers: React.FC<RelatedUsersProps> = ({
  client,
  disabled,
  setSnackbar,
  handleDispatchClient,
}) => {
  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "2rem",
          }}
        >
          <Typography variant="h5" component="h5">
            Related users
          </Typography>
          {!disabled && (
            <Button
              size="small"
              variant="contained"
              startIcon={<PersonAddIcon />}
              onClick={() => setInviteModalOpen(true)}
            >
              Invite users
            </Button>
          )}
        </Box>
        <Box sx={{ mb: "2rem", maxWidth: "1600px" }}>
          <Grid container columnSpacing={2} rowSpacing={4}>
            {client.linkedUsers.map((user: User) => {
              return (
                <Grid
                  item
                  lg={3}
                  md={4}
                  sm={12}
                  xs={12}
                  key={`User-${user.user.id}`}
                >
                  <UserCard
                    topText={RoleTypes[user.roleName as keyof typeof RoleTypes]}
                    centerText={`${user.user.firstName} ${user.user.lastName}`}
                    bottomText={user.user.email}
                    initials={getInitials(
                      user.user.firstName + " " + user.user.lastName,
                    )}
                    user={user}
                    setSnackbar={setSnackbar}
                    clientId={client.id}
                    handleDispatchClient={handleDispatchClient}
                    disabled={disabled}
                  />
                </Grid>
              );
            })}
            {client.invitedUsers.map((invite: Invite) => {
              return (
                <Grid
                  item
                  lg={3}
                  md={4}
                  sm={12}
                  xs={12}
                  key={`Invite-${invite.inviteTokenId}`}
                >
                  <UserCard
                    topText={`Invited as ${RoleTypes[invite.roleName as keyof typeof RoleTypes]}`}
                    centerText={invite.email}
                    bottomText={`Invited by ${invite.invitedBy}`}
                    isInvite
                    invite={invite}
                    setSnackbar={setSnackbar}
                    disabled={disabled}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      {inviteModalOpen && (
        <Modal open={inviteModalOpen} onClose={() => setInviteModalOpen(false)}>
          <Box>
            <NewUserModal
              setOpenModal={setInviteModalOpen}
              clientId={client.id}
            />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default RelatedUsers;
