import axiosClient from "api/axiosInstance";
import {
  CreateProductionPrognoseRequestDto,
  CreateProductionPrognoseResponseDto,
  PreviousDayPrognoseChartData,
  ProductionForecast,
  ProductionPrognoseSelect,
  ProductionPrognoseThirtyDayAbsoluteErrorData,
  UpdateProductionPrognosisDto,
} from "api/models/ProductionForecast";

export const getProductionPrognose = async (
  id: number,
): Promise<ProductionForecast> => {
  const client = axiosClient();
  const response = await client.get(`/production-prognosis/${id}`);
  return response.data;
};

export const createProductionPrognose = async (
  data: CreateProductionPrognoseRequestDto,
): Promise<CreateProductionPrognoseResponseDto> => {
  const client = axiosClient();
  const response = await client.post(`/production-prognosis`, data);
  return response.data;
};

export const getProductionPrognoses = async (
  date?: string,
): Promise<ProductionPrognoseSelect> => {
  const client = axiosClient();
  const response = await client.get(`/production-prognosis`, {
    params: { date },
  });
  return response.data;
};

export const updateProductionPrognose = async (
  id: number,
  data: UpdateProductionPrognosisDto,
): Promise<UpdateProductionPrognosisDto> => {
  const client = axiosClient();
  const response = await client.patch(`/production-prognosis/${id}`, data);
  return response.data;
};

export const verifyProductionPrognose = async (id: number) => {
  const client = axiosClient();
  const response = await client.put(`/production-prognosis/${id}/verify`);
  return response.data;
};

export const unverifyProductionPrognose = async (id: number) => {
  const client = axiosClient();
  const response = await client.put(`/production-prognosis/${id}/unverify`);
  return response.data;
};

export const getProductionForecastExport = async (
  forecastDate: string,
): Promise<any> => {
  const client = axiosClient();

  const response = await client.get(
    `/production-prognosis/export?date=${forecastDate}`,
    {
      responseType: "blob",
    },
  );

  return response.data;
};

export const getProductionForecastThirtyDayAbsoluteError = async (
  type: string,
): Promise<ProductionPrognoseThirtyDayAbsoluteErrorData> => {
  const client = axiosClient();

  const response = await client.post(
    `/production-prognosis/thirty-day-absolute-error`,
    { type },
  );

  return response.data;
};

export const getPastProductionForecastChartData = async (
  forecastDate: string,
  forecastType: string,
): Promise<PreviousDayPrognoseChartData> => {
  const client = axiosClient();

  const response = await client.get(
    `/production-prognosis/past-prognosis-data?date=${forecastDate}&type=${forecastType}`,
  );

  return response.data;
};

export const updateProductionForecastData = async (
  forecastDate: string,
  forecastType: string,
): Promise<ProductionForecast> => {
  const client = axiosClient();
  const response = await client.patch(
    `/production-prognosis/update-prognose-data`,
    null,
    {
      params: {
        date: forecastDate,
        type: forecastType,
      },
    },
  );
  return response.data.data;
};
