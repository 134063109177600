import axiosClient from "api/axiosInstance";
import { UserRole } from "../models/UserRole";

export const switchUserRole = async (roleId: number): Promise<UserRole> => {
  const client = axiosClient();

  const response = await client.patch(`/user-roles/${roleId}/switch-role`);

  return response.data;
};

export const removeUserRole = async (userRoleId: number): Promise<UserRole> => {
  const client = axiosClient();

  const response = await client.delete(`/user-roles/${userRoleId}`);

  return response.data;
};

export const updateUserRole = async (
  roleId: number,
  postData: any,
): Promise<UserRole> => {
  const client = axiosClient();

  const response = await client.put(`/user-roles/${roleId}`, postData);

  return response.data;
};
