export const meteringPointAdminColumns = [
  "eic",
  "client",
  "address",
  "city",
  "connectionState",
  "consumptionScale",
  "meteringType",
  "isBorderPoint",
  "operatorEic",
];

export const meteringPointAdminColumnsPlaceholders = [
  "EIC",
  "Client",
  "Address",
  "City",
  "Connection status",
  "Scale",
  "Type",
  "Border",
  "Operator EIC",
];

export const meteringPointUserColumns = [
  "eic",
  "address",
  "city",
  "connectionState",
  "consumptionScale",
  "meteringType",
  "isBorderPoint",
  "operatorEic",
];

export const meteringPointUserColumnsPlaceholers = [
  "EIC",
  "Address",
  "City",
  "Connection status",
  "Scale",
  "Type",
  "Border",
  "Operator EIC",
];
