import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import Table from "components/Table";
import { fetchAllContracts } from "store/appSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectContracts, selectMe } from "store/selectors";
import { ProtectedPaths } from "routes";
import FilterComponent from "components/FilterComponent/FilterComponent";
import { AttachFile } from "@mui/icons-material";
import { fetchExportContracts } from "api/resources/contracts";
import downloadCSV from "helpers/excelExport";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  adminContractColumns,
  adminContractColumnsPlaceholders,
  userContractColumns,
  userContractColumnsPlaceholers,
} from "../contractColumns";
import { getClientInfoFromRoles } from "helpers/clientInfoFromRoles";
import dayjs from "dayjs";

const ContractsTable = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [resetPage, setResetPage] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const contracts = useAppSelector(selectContracts);
  const me = useAppSelector(selectMe);

  useEffect(() => {
    if (me) {
      const { hasClientRole, clientName } = getClientInfoFromRoles(me.roles);
      if (hasClientRole) {
        setFilters((prevFilters) => ({ ...prevFilters, client: clientName }));
      }
      dispatch(
        fetchAllContracts(currentPage, 25, hasClientRole ? filters : {}),
      );
    }
  }, [currentPage, me]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        setCurrentPage(0);
        setResetPage(true);
        dispatch(fetchAllContracts(0, 25, filters));
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [filters, dispatch]);

  const handleFilter = (newFilters: { [key: string]: string }) => {
    if (me && getClientInfoFromRoles(me.roles)) {
      const clientName = getClientInfoFromRoles(me.roles);
      if (clientName) {
        newFilters.client = clientName.clientName;
      }
    }
    setFilters(newFilters);
  };

  const columns = [
    {
      accessorKey: "meteringPoint",
      header: "Metering point",
      cell: (row: any) => {
        return (
          row.row.original.meteringPoint.address +
          " " +
          row.row.original.meteringPoint.city +
          " (" +
          row.row.original.meteringPoint.eic +
          ")"
        );
      },
    },
    {
      accessorKey: "client",
      header: "Client",
      cell: (row: any) => {
        return (
          <Link to={`/${ProtectedPaths.Clients}/${row.row.original.client.id}`}>
            {row.row.original.client.name +
              " (" +
              row.row.original.client.eic +
              ")"}
          </Link>
        );
      },
    },
    {
      accessorKey: "firstDate",
      header: "Beginning of contract",
      cell: (row: any) => {
        return row.row.original.firstDate
          ? dayjs(row.row.original.firstDate).format("DD.MM.YYYY")
          : "";
      },
    },
    {
      accessorKey: "lastDate",
      header: "End of contract",
      cell: (row: any) => {
        return row.row.original.lastDate
          ? dayjs(row.row.original.lastDate).format("DD.MM.YYYY")
          : "";
      },
    },
    {
      accessorKey: "settings",
      header: "",
    },
  ];

  const handleExportContracts = () => {
    setLoading(true);

    fetchExportContracts(filters)
      .then((response) => {
        downloadCSV(response, `Contracts`);
      })
      .catch((error) => {
        console.error("Error exporting contracts", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Contracts</Typography>
        <LoadingButton
          loading={loading}
          variant="contained"
          size="small"
          startIcon={<AttachFile />}
          onClick={handleExportContracts}
        >
          Export contracts
        </LoadingButton>
      </Box>
      <FilterComponent
        onFilter={handleFilter}
        columns={
          me && getClientInfoFromRoles(me.roles).isAdmin
            ? adminContractColumns
            : userContractColumns
        }
        columnPlaceholders={
          me && getClientInfoFromRoles(me.roles).isAdmin
            ? adminContractColumnsPlaceholders
            : userContractColumnsPlaceholers
        }
      />
      <Table
        data={contracts?.content}
        columns={columns}
        isFetching={!contracts}
        pageCount={contracts?.totalPages}
        page={setCurrentPage}
        resetPage={resetPage}
        searchLabel="Search for client"
      />
    </>
  );
};

export default ContractsTable;
