import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { defaultStyle } from "components/Modals";
import { User } from "api/models/Client";
import Select from "components/Select";
import { updateUserRole } from "api/resources/userRoles";

interface EditUserRoleModalProps {
  user: User;
  clientId: number;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleDispatchClient: () => void;
}

const RoleTypeIds = {
  ROLE_CLIENT_ADMIN: 2,
  ROLE_CLIENT_USER: 3,
  ROLE_ADMIN: 1,
};

const EditUserRoleModal = ({
  setOpenModal,
  user,
  clientId,
  handleDispatchClient,
}: EditUserRoleModalProps) => {
  const [loading, setLoading] = useState(false);
  const [newRole, setNewRole] = useState(
    RoleTypeIds[user.roleName as keyof typeof RoleTypeIds],
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const postData = {
      userId: user.user.id,
      clientId: clientId,
      roleId: newRole,
    };

    updateUserRole(user.roleId, postData)
      .then(() => {
        setLoading(false);
        setOpenModal(false);
        handleDispatchClient();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Box sx={defaultStyle}>
      <Typography id="modal-modal-title" variant="h5" component="h5">
        {`Change the role for ${user.user.firstName} ${user.user.lastName}`}
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2} sx={{ mt: "1rem" }}>
          <Grid item sm={6}>
            <Select
              label="Role"
              id="role"
              name="role"
              minWidth={150}
              value={newRole}
              onChange={(e: any) => setNewRole(e.target.value)}
              options={[
                {
                  label: "User",
                  value: 3,
                },
                {
                  label: "Admin",
                  value: 2,
                },
              ]}
            />
          </Grid>
          <Grid item sm={12} sx={{ mt: "1rem" }}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              loadingIndicator="Sending..."
              disabled={loading}
            >
              Save role
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EditUserRoleModal;
