import React, { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Box, Grid, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import { defaultStyle } from "components/Modals";
import { sendContract } from "api/resources/contracts";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { Client } from "../../../api/models/Client";
import { Snackbar } from "../../../types/snackbar";

interface EnterContractModalProps {
  client: Client;
  selectedMeteringPoints: GridRowSelectionModel;
  setSelectedMeteringPoints: React.Dispatch<
    React.SetStateAction<GridRowSelectionModel>
  >;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbar: React.Dispatch<React.SetStateAction<Snackbar>>;
}

const EnterContractModal = ({
  client,
  setOpenModal,
  selectedMeteringPoints,
  setSelectedMeteringPoints,
  setSnackbar,
}: EnterContractModalProps) => {
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const postData = {
      firstDate: dayjs(startDate).format("YYYY-MM-DD").toString(),
      lastDate: dayjs(endDate).format("YYYY-MM-DD").toString(),
      meteringPoints: selectedMeteringPoints,
    };

    sendContract(postData, client.id)
      .then(() => {
        setLoading(false);
        setOpenModal(false);
        setSelectedMeteringPoints([]);
        setSnackbar({
          open: true,
          severity: "success",
          message: "Contract entered successfully",
        });
      })
      .catch((error) => {
        setSnackbar({
          open: true,
          severity: "error",
          message: error.response?.data?.message,
        });
        setLoading(false);
        setOpenModal(false);
        setSelectedMeteringPoints([]);
      });
  };

  return (
    <Box sx={defaultStyle}>
      <Typography id="modal-modal-title" variant="h5" component="h5">
        Enter a new contract
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2} sx={{ mt: "1rem", mb: "2rem" }}>
          <Grid item sm={6}>
            <DatePicker
              label="Start date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              slotProps={{ textField: { fullWidth: true, size: "small" } }}
            />
          </Grid>
          <Grid item sm={6}>
            <DatePicker
              label="End date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              slotProps={{ textField: { fullWidth: true, size: "small" } }}
            />
          </Grid>
          <Grid item sm={12} sx={{ mt: "1rem" }}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              loadingIndicator="Sending..."
              disabled={loading}
            >
              Enter contract
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EnterContractModal;
