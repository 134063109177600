import axiosClient from "../axiosInstance";
import { ElevationCoefficient } from "../models/ElevationCoefficients";

export const uploadNewCoefficients = async (
  file: File,
): Promise<ElevationCoefficient> => {
  const client = axiosClient();
  const formData = new FormData();
  formData.append("file", file);

  const response = await client.post(
    `/elevation-coefficients/upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

  return response.data;
};

export const getCoefficients = async (): Promise<ElevationCoefficient[]> => {
  const client = axiosClient();
  const response = await client.get(`/elevation-coefficients`);

  return response.data;
};
