import React from "react";
import { Box, Button, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { grey } from "@mui/material/colors";
import SyncIcon from "@mui/icons-material/Sync";

interface ForecastActionsProps {
  isEditable: boolean;
  isVerified: boolean;
  handleConfirmForecast: () => void;
  handleSaveForecast: () => void;
  handleUnconfirmForecast: () => void;
  handleUpdateProductionPrognosisData?: () => void;
  saving: boolean;
  updating?: boolean;
}

const ForecastActions: React.FC<ForecastActionsProps> = ({
  isEditable,
  isVerified,
  handleConfirmForecast,
  handleSaveForecast,
  handleUnconfirmForecast,
  handleUpdateProductionPrognosisData,
  saving,
  updating,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {isEditable && !isVerified && (
        <Box>
          <LoadingButton
            variant="outlined"
            size="small"
            loading={updating}
            loadingIndicator="Updating..."
            startIcon={<SyncIcon />}
            sx={{ mr: "0.6rem" }}
            onClick={handleUpdateProductionPrognosisData}
          >
            Update
          </LoadingButton>
        </Box>
      )}
      {isEditable && !isVerified && (
        <Box>
          <Button
            variant="outlined"
            size="small"
            sx={{ mr: "0.6rem" }}
            onClick={handleConfirmForecast}
          >
            Confirm forecast
          </Button>
        </Box>
      )}
      {isEditable && !isVerified && (
        <Box>
          <LoadingButton
            variant="contained"
            size="small"
            loading={saving}
            loadingIndicator="Saving..."
            disabled={saving}
            onClick={handleSaveForecast}
          >
            Save forecast
          </LoadingButton>
        </Box>
      )}
      {isVerified && (
        <Box>
          <Button
            variant="contained"
            size="small"
            sx={{ mr: "0.6rem" }}
            onClick={handleUnconfirmForecast}
          >
            Open for changes
          </Button>
        </Box>
      )}
      {!isEditable && (
        <Box>
          <Typography component="p" sx={{ fontSize: "14px", color: grey[600] }}>
            This forecast is not editable because it is in the past.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ForecastActions;
