import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {
  Alert,
  Badge,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Popover,
  Snackbar,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CAvatar from "components/Avatar";
import styles from "./NotifcationsBell.module.scss";
import { InviteTokenData } from "api/models/Invite";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectInvites, selectMe } from "../../store/selectors";
import { fetchMe } from "../../store/appSlice";
import { useNavigate } from "react-router-dom";
import { getInitials } from "../../helpers/users";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { acceptInvite, declineInvite } from "../../api/resources/invites";

const NotificationsBell = () => {
  const initialNotifications = useAppSelector(selectInvites);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [notifications, setNotifications] =
    useState<InviteTokenData[]>(initialNotifications);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedNotification, setSelectedNotification] =
    useState<InviteTokenData | null>(null);

  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({ open: false, message: "", severity: "success" });
  const dispatch = useAppDispatch();
  const me = useAppSelector(selectMe);
  const navigate = useNavigate();

  useEffect(() => {
    setNotifications([...initialNotifications]);
    setLoading(false);
  }, [initialNotifications]);

  function parseRole(inviteData: InviteTokenData): string {
    const { roleName } = inviteData;
    if (roleName === "ROLE_ADMIN" || roleName === "ROLE_CLIENT_ADMIN") {
      return "administrator";
    }
    return "user";
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const handleOpenDialog = (notification: InviteTokenData) => {
    setSelectedNotification(notification);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedNotification(null);
  };

  const handleAccept = async () => {
    if (selectedNotification) {
      try {
        await acceptInvite(selectedNotification.inviteToken);
        setSnackbar({
          open: true,
          message: "Invite accepted",
          severity: "success",
        });
        setNotifications((prevNotifications) =>
          prevNotifications.map((notification) =>
            notification.inviteToken === selectedNotification.inviteToken
              ? { ...notification, accepted: true }
              : notification,
          ),
        );
        dispatch(fetchMe());
        navigate("/");
      } catch (error) {
        console.error("Error accepting invite:", error);
        setSnackbar({
          open: true,
          message: "Error accepting invite",
          severity: "error",
        });
      }
    }
    handleCloseDialog();
  };

  const handleDecline = async () => {
    if (selectedNotification) {
      try {
        await declineInvite(selectedNotification.inviteToken);
        setSnackbar({
          open: true,
          message: "Invite declined",
          severity: "success",
        });
        if (me.roles.length !== 0) {
          dispatch(fetchMe());
        }
      } catch (error) {
        console.error("Error declining invite:", error);
        setSnackbar({
          open: true,
          message: "Error declining invite",
          severity: "error",
        });
      }
    }
    handleCloseDialog();
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const open = Boolean(anchor);
  const id = open ? "simple-popover" : undefined;

  const hasNotifications = notifications.length > 0;

  const unacceptedNotificationsCount = notifications.filter(
    (notification) => notification.accepted == null,
  ).length;

  return (
    <Box sx={{ mr: hasNotifications ? 4 : 2 }}>
      <IconButton size="large" sx={{ color: "white" }} onClick={handleClick}>
        <Badge color="error" badgeContent={unacceptedNotificationsCount}>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box className={styles.popup}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Box className={styles.list}>
              {hasNotifications ? (
                notifications
                  .sort(
                    (a, b) => +new Date(b.inviteDate) - +new Date(a.inviteDate),
                  )
                  .slice(0, 10)
                  .map((notification) => (
                    <button
                      key={`Notification-${notification.inviteDate}`}
                      className={classNames(
                        styles.listButton,
                        notification.accepted
                          ? styles.accepted
                          : styles.unaccepted,
                      )}
                      onClick={() => handleOpenDialog(notification)}
                      disabled={notification.accepted == false}
                    >
                      <CAvatar
                        style={{ marginRight: "1rem" }}
                        extraAvatar={{
                          initials: getInitials(notification.invitedBy),
                        }}
                      >
                        {getInitials(notification.clientName)}
                      </CAvatar>
                      <Box>
                        <Typography
                          sx={{ fontSize: "14px" }}
                        >{`${notification.invitedBy} has invited you to ${notification.clientName} as an ${parseRole(notification)}`}</Typography>
                        <label className={styles.label}>
                          {notification.accepted
                            ? "Accepted"
                            : "Waiting for response"}
                          {" - "}
                          {`${dayjs().to(dayjs(notification.inviteDate))}`}
                        </label>
                      </Box>
                    </button>
                  ))
              ) : (
                <Box margin={2}>No notifications</Box>
              )}
            </Box>
          )}
        </Box>
      </Popover>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          style: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem",
          },
        }}
      >
        <DialogTitle>Invitation</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ textAlign: "center" }}>
            {selectedNotification &&
              `${selectedNotification.invitedBy} has invited you to ${selectedNotification.clientName} as an ${parseRole(selectedNotification)}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleAccept}
            color="primary"
            variant={"contained"}
            autoFocus
          >
            Accept
          </Button>
          <Button onClick={handleDecline} variant={"outlined"} color="primary">
            Decline
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NotificationsBell;
