import axiosClient from "api/axiosInstance";

export const saveSupplyPlanForecast = async (
  date: string,
  data: any,
): Promise<any> => {
  const client = axiosClient();

  const response = await client.post(
    `/supply-plans/prognosis?date=${date}`,
    data,
  );

  return response.data;
};

export const updateSupplyPlanForecast = async (
  date: string,
  data: any,
): Promise<any> => {
  const client = axiosClient();

  const response = await client.put(
    `/supply-plans/prognosis?date=${date}`,
    data,
  );

  return response.data;
};

export const getSupplyPlanForecast = async (date: string): Promise<any> => {
  const client = axiosClient();

  const response = await client.get(`/supply-plans/prognosis/${date}`);

  return response.data;
};

export const downloadSupplyPlanForecastTemplate = async (
  date: string,
): Promise<any> => {
  const client = axiosClient();

  const response = await client.post(
    `/supply-plans/prognosis/template?date=${date}`,
  );

  return response.data;
};

export const uploadSupplyPlanForecast = async (
  date: string,
  data: any,
): Promise<any> => {
  const client = axiosClient();

  const response = await client.post(
    `/supply-plans/prognosis/upload?date=${date}`,
    { file: data },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );

  return response.data;
};

export const getSupplyPlanPriceLimits = async (): Promise<any> => {
  const client = axiosClient();

  const response = await client.get(`/supply-plan-price-limits`);

  return response.data;
};

export const saveSupplyPlanPriceLimits = async (data: any): Promise<any> => {
  const client = axiosClient();

  const response = await client.post(`/supply-plan-price-limits`, data);

  return response.data;
};

export const downloadSupplyPlanPriceLimits = async (): Promise<any> => {
  const client = axiosClient();

  const response = await client.post(`/supply-plan-price-limits/export`);

  return response.data;
};
