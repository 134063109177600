import { Box } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import { green, indigo, orange, pink, red } from "@mui/material/colors";
import { ForecastPeriodData } from "api/models/Forecast";

interface ForecastLineChartProps {
  periodsData?: ForecastPeriodData[];
  averageLine: number[];
  forecastLine: number[];
  meteologicaLine?: number[];
  aiolosLine?: number[];
  forecaLine?: number[];
  tableMatrix?: any;
}

interface SeriesData {
  data: number[];
  id: number;
  label: string;
  showMark: boolean;
  color?: string;
}

const xAxisData = Array.from({ length: 25 }, (_, i) => i);

const xAxis = [
  {
    data: xAxisData,
    valueFormatter: (value: number) => {
      const hour = value.toString().padStart(2, "0");
      return `${hour}:00 - ${hour}:59`;
    },
  },
];

const ProductionForecastLineChart = ({
  averageLine,
  meteologicaLine,
  aiolosLine,
  forecaLine,
  forecastLine,
}: ForecastLineChartProps) => {
  const series: SeriesData[] = [];

  series.push({
    data: averageLine,
    id: 10,
    label: "Total",
    color: indigo[900],
    showMark: false,
  });

  series.push({
    data: forecastLine,
    id: 11,
    label: "Forecast",
    color: pink.A400,
    showMark: false,
  });

  if (meteologicaLine && meteologicaLine.some((x) => x > 0)) {
    series.push({
      data: meteologicaLine,
      id: 12,
      label: "Meteologica",
      color: "#f9f098",
      showMark: false,
    });
  }

  if (aiolosLine && aiolosLine.some((x) => x > 0)) {
    series.push({
      data: aiolosLine,
      id: 13,
      label: "Aiolos",
      color: "#9ddba1",
      showMark: false,
    });
  }

  if (forecaLine && forecaLine.some((x) => x > 0)) {
    series.push({
      data: forecaLine,
      id: 14,
      label: "Foreca",
      color: "#d0a8ff",
      showMark: false,
    });
  }

  const allData = [
    ...series.flatMap((s) => s.data),
    ...averageLine,
    ...forecastLine,
  ];
  const calculatedMin = Math.min(...allData);
  const calculatedMax = Math.max(...allData);

  const yAxis = [
    {
      min: calculatedMin - 0.1,
      max: calculatedMax + 0.1,
      valueFormatter: (value: number) => value.toString(),
    },
  ];

  return (
    <Box>
      <LineChart
        xAxis={xAxis}
        yAxis={yAxis}
        series={series}
        width={530}
        height={380}
        grid={{ vertical: true, horizontal: true }}
        margin={{ left: 40, right: 40, top: 40, bottom: 90 }}
        colors={[
          "#fbb4ae",
          "#b3cde3",
          "#ccebc5",
          "#decbe4",
          "#fed9a6",
          "#ffffcc",
          "#e5d8bd",
          "#fddaec",
          "#f2f2f2",
        ]}
        slotProps={{
          legend: {
            position: {
              vertical: "bottom",
              horizontal: "middle",
            },
            itemMarkWidth: 15,
            itemMarkHeight: 2,
            markGap: 5,
            itemGap: 5,
          },
        }}
        sx={{
          "& .MuiChartsLegend-series text": { fontSize: "12px !important" },
          padding: "5px",
        }}
      />
    </Box>
  );
};

export default ProductionForecastLineChart;
