import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "layouts/Main";
import {
  Box,
  Button,
  Divider,
  Card,
  Grid,
  TextField,
  Typography,
  IconButton,
  Alert,
} from "@mui/material";
import CAvatar from "components/Avatar";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getInitials } from "helpers/users";
import { grey } from "@mui/material/colors";

import styles from "./UserProfile.module.scss";
import Select from "components/Select";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectUser } from "store/selectors";
import { fetchUser } from "store/appSlice";
import { editUser } from "../../../../api/resources/users";
import { FormErrorProps } from "../../../../api/models/FormErrorProps";

const UserProfile = () => {
  const [snackbar, setSnackbar] = useState(null);
  const [formErrors, setFormErrors] = useState(null);
  const navigate = useNavigate();
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  useEffect(() => {
    dispatch(fetchUser(Number(userId)));
  }, [dispatch, userId]);

  const snackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(null);
  };

  const handleSave = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const postData = {
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      email: data.get("email"),
    };

    editUser(Number(userId), postData)
      .then(() => {
        dispatch(fetchUser(Number(userId)));
      })
      .catch((error) => {
        console.log(error);
        setFormErrors(error.response.data.errors);
      });
  };

  return (
    <MainLayout
      snackbar={{
        open: !!snackbar,
        handleClose: snackbarClose,
        type: snackbar?.type,
        message: snackbar?.message,
      }}
    >
      {user && (
        <>
          <Box sx={{ mt: 4 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <IconButton
                sx={{ mr: "0.6rem" }}
                size="small"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography component="p">Back</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <CAvatar size="extralarge">
                {getInitials(user.firstName + " " + user.lastName)}
              </CAvatar>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  ml: 2,
                }}
              >
                <Typography variant="h5" component="h5">
                  {`${user.firstName} ${user.lastName}`}
                </Typography>
                <Typography component="p" sx={{ color: grey[600] }}>
                  {user.email}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box component="form" onSubmit={handleSave} noValidate>
            <Grid container columnSpacing={0} sx={{ mt: "1rem", mb: "2rem" }}>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Grid container spacing={2} sx={{ mt: "1rem", mb: "2rem" }}>
                  <Grid item sm={12}>
                    <Divider textAlign="left">Account information</Divider>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="firstName"
                      name="firstName"
                      label="First name"
                      size="small"
                      defaultValue={user.firstName}
                      required
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      id="lastName"
                      name="lastName"
                      label="Last name"
                      defaultValue={user.lastName}
                      size="small"
                      required
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      defaultValue={user.email}
                      size="small"
                      required
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sm={12} xs={12}>
                <Grid container spacing={2} sx={{ mt: "1rem", mb: "2rem" }}>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Divider textAlign="left">Companies</Divider>
                    <Grid item sm={12} xs={12} sx={{ mt: 2 }}>
                      {user.linkedClients.length === 0 && (
                        <Typography
                          component="p"
                          sx={{
                            color: grey[600],
                            fontSize: "15 px",
                          }}
                        >
                          User is not linked to any companies
                        </Typography>
                      )}
                      {user.linkedClients.length > 0 &&
                        user.linkedClients.map((linkedClient) => (
                          <Card
                            sx={{
                              padding: 2,
                              mb: 2,
                              boxShadow: 0,
                              border: 1,
                              borderColor: grey[300],
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                              className={styles.companyCard}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                }}
                              >
                                <CAvatar size="medium">
                                  {getInitials(linkedClient.clientName)}
                                </CAvatar>
                                <Box sx={{ ml: 2 }}>
                                  <Typography>
                                    {linkedClient.clientName}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="center"
                              >
                                {linkedClient.roleId === 1 ? (
                                  <Box>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        textTransform: "uppercase",
                                        fontWeight: "500",
                                        letterSpacing: "0.4px",
                                        color: grey[600],
                                      }}
                                    >
                                      Superadmin
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Select
                                    label="Role"
                                    id="role"
                                    name="role"
                                    minWidth={150}
                                    defaultValue={linkedClient.roleId}
                                    disabled
                                    options={[
                                      {
                                        label: "User",
                                        value: 3,
                                      },
                                      {
                                        label: "Admin",
                                        value: 2,
                                      },
                                    ]}
                                  />
                                )}
                              </Box>
                            </Box>
                          </Card>
                        ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {formErrors && (
                <Grid item sm={12}>
                  {formErrors.map(({ message }: FormErrorProps) => (
                    <Alert
                      variant="filled"
                      severity="error"
                      sx={{ mb: "0.4rem" }}
                    >
                      {message}
                    </Alert>
                  ))}
                </Grid>
              )}
              <Grid item sm={12}>
                <Button variant="contained" type="submit" sx={{ mr: "1rem" }}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </MainLayout>
  );
};
export default UserProfile;
