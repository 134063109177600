export enum PermissionTypes {
  PERMISSION_REPORTS = "Reports",
  PERMISSION_PROGNOSES = "Forecasts",
  PERMISSION_SETTINGS = "Settings",
  PERMISSION_REGISTERS = "Registers",
  PERMISSION_PUBLIC = "Public",
}

export enum MeteringPointTypes {
  CONNECTED = "Connected",
  DISCONNECTED = "Disconnected",
  SMALL = "Small",
  LARGE = "Large",
  REMOTE_READING = "Remote",
  ONE = "1",
  false = "No",
  true = "Yes",
}

export enum PeriodPresetTypes {
  YESTERDAY = "Yesterday",
  CURRENT_WEEK = "Current week",
  CURRENT_MONTH = "Current month",
  LAST_MONTH = "Last month",
  CURRENT_QUARTER = "Current quarter",
  LAST_QUARTER = "Last quarter",
  CURRENT_HALF = "Current half",
  LAST_HALF = "Last half",
  CURRENT_YEAR = "Current year",
  LAST_YEAR = "Last year",
}

export enum ReadingTypeTypes {
  CONSUMPTION = "Consumption",
  PRODUCTION = "Production",
}

export enum ReadingSumTypeTypes {
  GROUP_BY_CLIENTS = "Group by clients",
  ALL = "All",
}

export enum RoleTypes {
  ROLE_ADMIN = "Superadmin",
  ROLE_CLIENT_ADMIN = "Admin",
  ROLE_CLIENT_USER = "User",
}
