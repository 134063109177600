import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Alert,
  Divider,
  IconButton,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Select from "components/Select";
import { defaultStyle } from "components/Modals";
import { inviteUser } from "api/resources/users";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ClearIcon from "@mui/icons-material/Clear";
import { FormErrorProps } from "api/models/FormErrorProps";
import styles from "./NewUser.module.scss";
import AddIcon from "@mui/icons-material/Add";
import SingleClientSelect from "components/SingleClientSelect";
import { validateEmail } from "helpers/users";

interface NewUserModalProps {
  clientId?: number;
  requireClient?: boolean;
  dispatch?: any;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface InviteType {
  email: string;
  role: string;
}

const NewUserModal = ({
  clientId,
  requireClient,
  dispatch,
  setOpenModal,
}: NewUserModalProps) => {
  const [formErrors, setFormErrors] = useState(null);
  const [fieldError, setFieldError] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [inviteList, setInviteList] = useState([]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    inviteList.slice(0, 1).map((invite) => {
      const linkedClients: { clientId: any; roleId: any }[] = [];

      invite.clientsList.map(
        (listItem: { client: { value: any }; roleId: any }) => {
          linkedClients.push({
            clientId: listItem.client.value,
            roleId: listItem.roleId,
          });
        },
      );

      const inviteData = {
        email: invite.email,
        linkedClients,
      };

      inviteUser(inviteData)
        .then(() => {
          setOpenModal(false);
          if (dispatch) {
            dispatch();
          }
        })
        .catch((error) => {
          console.log({ error });
          setFormErrors(error.response.data.errors);
        });
    });
  };

  const handleEnterEmail = () => {
    const inviteListCopy = [...inviteList];

    if (!validateEmail(emailValue)) {
      setFieldError("Please enter a correct email");
    } else if (inviteList.filter((il) => il.email == emailValue).length > 0) {
      setFieldError("Email already added to invite list");
    } else {
      inviteListCopy.push({
        email: emailValue,
        clientsList: [
          { client: { title: "", value: clientId || 0 }, roleId: 3 },
        ],
      });
      setEmailValue("");
    }

    setInviteList(inviteListCopy);
  };

  const handleRemoveInviteFromList = (invite: InviteType) => {
    const inviteListCopy = [
      ...inviteList.filter((il) => il.email !== invite.email),
    ];

    setInviteList(inviteListCopy);
  };

  const handleSelectRole = (
    invite: InviteType,
    role: string,
    rowIndex?: number,
  ) => {
    const inviteListCopy = [...inviteList];

    const inviteIndex = inviteListCopy.findIndex(
      (il) => il.email === invite.email,
    );

    if (requireClient && rowIndex !== null) {
      inviteListCopy[inviteIndex].clientsList[rowIndex].roleId = role;
    } else {
      inviteListCopy[inviteIndex].clientsList[0].roleId = role;
    }

    setInviteList(inviteListCopy);
  };

  const handleAddRoleRow = (invite: InviteType) => {
    const inviteListCopy = [...inviteList];

    const inviteIndex = inviteListCopy.findIndex(
      (il) => il.email === invite.email,
    );

    inviteListCopy[inviteIndex].clientsList.push({
      client: { title: "", value: clientId || 0 },
      roleId: 3,
    });

    setInviteList(inviteListCopy);
  };

  const handleRemoveRoleRow = (invite: InviteType, rowIndex: number) => {
    const inviteListCopy = [...inviteList];

    const inviteIndex = inviteListCopy.findIndex(
      (il) => il.email === invite.email,
    );

    inviteListCopy[inviteIndex].clientsList.splice(rowIndex, 1);

    setInviteList(inviteListCopy);
  };

  const handleSelectClient = (
    invite: { email: string },
    rowIndex: number,
    client: any,
  ) => {
    const inviteListCopy = [...inviteList];

    const inviteIndex = inviteListCopy.findIndex(
      (il) => il.email === invite.email,
    );

    inviteListCopy[inviteIndex].clientsList[rowIndex].client = client;

    setInviteList(inviteListCopy);
  };

  return (
    <Box sx={defaultStyle}>
      <Typography id="modal-modal-title" variant="h5" component="h5">
        Invite users
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2} sx={{ mt: "1rem" }}>
          <Grid
            item
            sm={12}
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <TextField
              id="email"
              name="email"
              label="Enter email"
              autoFocus={true}
              size="small"
              error={!!fieldError}
              helperText={fieldError}
              value={emailValue}
              onChange={(e) => {
                setFieldError("");
                setEmailValue(e.target.value);
              }}
              required
              sx={{ width: "100%" }}
            />
            {emailValue && (
              <Button
                variant="contained"
                size="large"
                sx={{ ml: "0.6rem" }}
                onClick={() => handleEnterEmail()}
              >
                <KeyboardReturnIcon />
              </Button>
            )}
          </Grid>
          {inviteList.length > 0 && (
            <Grid item sm={12} sx={{ mt: "1rem" }}>
              <Typography
                component="p"
                sx={{
                  fontSize: "11px",
                  lineHeight: "11px",
                  fontWeight: "500",
                  letterSpacing: "0.8px",
                  color: grey[700],
                  textTransform: "uppercase",
                  mb: "1rem",
                }}
              >
                Invites
              </Typography>
              <Grid item sm={12}>
                {inviteList.map((invite, inviteIndex) => (
                  <Box
                    key={`Invite-${invite.email}`}
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="space-between"
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography component="p" sx={{ padding: "0.7rem 0" }}>
                        {invite.email}
                      </Typography>
                      <Box>
                        {!!clientId && (
                          <Select
                            label="Role"
                            id="role"
                            name="role"
                            minWidth={150}
                            value={invite.clientsList[0].roleId}
                            onChange={(e: any) =>
                              handleSelectRole(invite, e.target.value)
                            }
                            options={[
                              {
                                label: "User",
                                value: 3,
                              },
                              {
                                label: "Admin",
                                value: 2,
                              },
                            ]}
                          />
                        )}
                        <IconButton
                          sx={{ ml: "1rem" }}
                          onClick={() => handleRemoveInviteFromList(invite)}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    {requireClient && (
                      <Box>
                        {invite.clientsList.map(
                          (
                            clientRow: {
                              client: { title: string; value: number };
                              roleId: number;
                            },
                            rowIndex: number,
                          ) => (
                            <Box
                              key={`ClientRow-${clientRow.client.value}`}
                              className={styles.clientRow}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                mt: 2,
                              }}
                            >
                              <div className={styles.clientRowLine}></div>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center",
                                }}
                              >
                                <SingleClientSelect
                                  invite={invite}
                                  rowIndex={rowIndex}
                                  selectedClient={clientRow.client}
                                  setSelectedClient={handleSelectClient}
                                  maxWidth={300}
                                  minWidth={300}
                                />
                                <Select
                                  label="Role"
                                  id="role"
                                  name="role"
                                  minWidth={150}
                                  value={clientRow.roleId}
                                  onChange={(e: any) =>
                                    handleSelectRole(
                                      invite,
                                      e.target.value,
                                      rowIndex,
                                    )
                                  }
                                  options={[
                                    {
                                      label: "User",
                                      value: 3,
                                    },
                                    {
                                      label: "Admin",
                                      value: 2,
                                    },
                                  ]}
                                />
                                {invite.clientsList.length > 1 && (
                                  <IconButton
                                    sx={{ ml: "1rem" }}
                                    onClick={() =>
                                      handleRemoveRoleRow(invite, rowIndex)
                                    }
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                )}
                              </Box>
                            </Box>
                          ),
                        )}
                      </Box>
                    )}
                    {requireClient &&
                      invite.clientsList.filter(
                        (cl: { client: { value: number } }) =>
                          !cl.client?.value,
                      ).length === 0 && (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Button
                            startIcon={<AddIcon />}
                            variant="text"
                            sx={{ mt: 1, width: "100%" }}
                            onClick={() => handleAddRoleRow(invite)}
                          >
                            Add role
                          </Button>
                        </Box>
                      )}
                    {inviteIndex + 1 < inviteList.length && (
                      <Divider sx={{ margin: "0.6rem 0" }} />
                    )}
                  </Box>
                ))}
              </Grid>
            </Grid>
          )}
          {formErrors && (
            <Grid item sm={12}>
              {formErrors.map(({ message }: FormErrorProps) => (
                <Alert variant="filled" severity="error" sx={{ mb: "0.4rem" }}>
                  {message}
                </Alert>
              ))}
            </Grid>
          )}
          <Grid item sm={12} sx={{ mt: "1rem" }}>
            <Button
              variant="contained"
              type="submit"
              disabled={
                inviteList.length < 1 ||
                inviteList.filter((invite) =>
                  invite.clientsList.some(
                    (cl: { client: { value: number } }) =>
                      cl.client === null || cl.client?.value === 0,
                  ),
                ).length > 0
              }
            >
              Send invites
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default NewUserModal;
