import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  InputAdornment,
  Grid,
} from "@mui/material";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { hours, periods } from "helpers/periods";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectSupplyPlanPriceLimits } from "store/selectors";
import { fetchSupplyPlanPriceLimits } from "store/appSlice";
import {
  downloadSupplyPlanPriceLimits,
  saveSupplyPlanPriceLimits,
} from "api/resources/supplyPlan";
import downloadCSV from "helpers/excelExport";

interface PriceLimitsProps {
  setSnackbar: (snackbar: { type: string; message: string }) => void;
}

interface PeriodPriceLimits {
  period: string;
  buy: number;
  sell: number;
}

const PriceLimits = ({ setSnackbar }: PriceLimitsProps) => {
  const [periodPriceLimits, setPeriodPriceLimits] = useState([]);
  const [valuesChanged, setValuesChanged] = useState(false);

  const dispatch = useAppDispatch();
  const supplyPlanPriceLimits = useAppSelector(selectSupplyPlanPriceLimits);

  useEffect(() => {
    dispatch(fetchSupplyPlanPriceLimits());
  }, [dispatch]);

  const generateTable = () => {
    const periodPriceLimitsCopy = [];

    periods.slice(0, 24).map((period, periodIndex) => {
      periodPriceLimitsCopy.push({
        period: period,
        buy: supplyPlanPriceLimits?.[0].values[periodIndex].buy || "",
        sell: supplyPlanPriceLimits?.[0].values[periodIndex].sell || "",
      });
    });

    setPeriodPriceLimits(periodPriceLimitsCopy);
  };

  useEffect(() => {
    generateTable();
  }, [supplyPlanPriceLimits]);

  const handleSavePriceLimits = () => {
    setValuesChanged(false);
    const values = [];

    periodPriceLimits.map((period, periodIndex) => {
      values.push({
        hour: hours[periodIndex],
        buy: period.buy,
        sell: period.sell,
      });
    });

    const postData = {
      values: values,
    };

    saveSupplyPlanPriceLimits(postData)
      .then(() =>
        setSnackbar({
          type: "success",
          message: "Price limits updated",
        }),
      )
      .catch((error) => {
        setSnackbar({
          type: "error",
          message: "Error saving price limits",
        });
        console.log(error);
      });
  };

  const handleDownloadPriceLimits = () => {
    downloadSupplyPlanPriceLimits().then((result) => {
      downloadCSV(result, `Supply_plan_price_limits`);
    });
  };

  return (
    <Grid container columnSpacing={8}>
      <Grid item sm={6}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {valuesChanged && (
            <Button
              variant="contained"
              onClick={() => handleSavePriceLimits()}
              sx={{ mb: 2 }}
            >
              Save
            </Button>
          )}
        </Box>
        <TableContainer component={Paper} sx={{ maxWidth: 400 }}>
          <Table sx={{ maxWidth: 400 }} size="small">
            <TableHead sx={{ bgcolor: "#eeeeee" }}>
              <TableRow>
                <TableCell>Period (UTC +2)</TableCell>
                <TableCell>Sell (€)</TableCell>
                <TableCell>Buy (€)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {periodPriceLimits.map((row, rowIndex) => (
                <TableRow key={"Row-" + row.period}>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography component="p">{row.period}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <FormControl variant="standard">
                      <Input
                        id="sell"
                        type="number"
                        size="small"
                        placeholder="-500"
                        value={row.sell || ""}
                        sx={{ maxWidth: "100px" }}
                        startAdornment={
                          <InputAdornment position="start">
                            <ChevronRightIcon />
                          </InputAdornment>
                        }
                        onChange={(e) => {
                          const periodPriceLimitsCopy: PeriodPriceLimits[] = [
                            ...periodPriceLimits,
                          ];
                          setValuesChanged(true);

                          periodPriceLimitsCopy[rowIndex].sell = Number(
                            e.target.value,
                          );

                          setPeriodPriceLimits(periodPriceLimitsCopy);
                        }}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl variant="standard">
                      <Input
                        id="buy"
                        type="number"
                        size="small"
                        placeholder="4000"
                        value={row.buy || ""}
                        sx={{ maxWidth: "100px" }}
                        startAdornment={
                          <InputAdornment position="start">
                            <ChevronLeftIcon />
                          </InputAdornment>
                        }
                        onChange={(e) => {
                          const periodPriceLimitsCopy: PeriodPriceLimits[] = [
                            ...periodPriceLimits,
                          ];
                          setValuesChanged(true);

                          periodPriceLimitsCopy[rowIndex].buy = Number(
                            e.target.value,
                          );

                          setPeriodPriceLimits(periodPriceLimitsCopy);
                        }}
                      />
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item sm={6}>
        <Typography component="p">
          Here you can update your price limits for each period. This table does
          not need to be updated daily.
        </Typography>
        <Typography component="p" sx={{ mt: "1rem" }}>
          The latest price limits will always be taken into account.
        </Typography>
        <Button
          variant="outlined"
          size="small"
          sx={{ mt: 2 }}
          onClick={() => handleDownloadPriceLimits()}
        >
          Export price limits
        </Button>
      </Grid>
    </Grid>
  );
};

export default PriceLimits;
