import { DataGrid, GridRowSelectionModel } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { MeteringPoint } from "api/models/MeteringPoint";
import dayjs from "dayjs";

interface MeasurementPointsProps {
  clientId: string;
  meteringPoints: {
    totalElements: number;
    totalPages: number;
    size: number;
    content: MeteringPoint[];
  };
  rowSelectionModel: GridRowSelectionModel;
  setRowSelectionModel: React.Dispatch<
    React.SetStateAction<GridRowSelectionModel>
  >;
  paginationModel: { page: number; pageSize: number };
  setPaginationModel: React.Dispatch<
    React.SetStateAction<{ page: number; pageSize: number }>
  >;
}

const MeasurementPoints = ({
  meteringPoints,
  rowSelectionModel,
  setRowSelectionModel,
  paginationModel,
  setPaginationModel,
}: MeasurementPointsProps) => {
  const columns = [
    {
      field: "EIC",
      flex: 1,
    },
    {
      field: "Address",
      flex: 1,
    },
    {
      field: "Municipality",
      flex: 1,
    },
    {
      field: "Active grid agreements",
      flex: 1,
    },
    {
      field: "Active supply agreements",
      flex: 1,
    },
    {
      field: "NPM valid agreements",
      flex: 1,
    },
    {
      field: "Valid operator",
      flex: 1,
    },
    {
      field: "Last sync",
      flex: 1,
    },
  ];

  const contractPeriod = (startDate: string, endDate?: string) => {
    return endDate ? `${startDate} - ${endDate}` : `${startDate} - ...`;
  };

  const rows = meteringPoints?.content.map((meteringPoint) => {
    return {
      id: meteringPoint.id,
      EIC: meteringPoint.eic,
      Address: meteringPoint.address,
      Municipality: meteringPoint.city,
      "Active grid agreements": meteringPoint.gridAgreementFirstDate
        ? `${dayjs(meteringPoint.gridAgreementFirstDate).format("DD.MM.YYYY")} - ...`
        : null,
      "Active supply agreements": meteringPoint.supplyAgreementFirstDate
        ? contractPeriod(
            dayjs(meteringPoint.supplyAgreementFirstDate).format("DD.MM.YYYY"),
            meteringPoint.supplyAgreementLastDate
              ? dayjs(meteringPoint.supplyAgreementLastDate).format(
                  "DD.MM.YYYY",
                )
              : "...",
          )
        : null,
      "NPM valid agreements": meteringPoint.contract
        ? contractPeriod(
            meteringPoint.contract.firstDate
              ? dayjs(meteringPoint.contract.firstDate).format("DD.MM.YYYY")
              : "",
            meteringPoint.contract.lastDate
              ? dayjs(meteringPoint.contract.lastDate).format("DD.MM.YYYY")
              : "",
          )
        : null,
      "Valid operator": meteringPoint.operatorEic,
      "Last sync": meteringPoint.lastReadingsSyncedAt
        ? dayjs(meteringPoint.lastReadingsSyncedAt).format("DD.MM.YYYY HH:mm")
        : null,
    };
  });

  return (
    <Box sx={{ mt: "2rem" }}>
      <DataGrid
        checkboxSelection
        onRowSelectionModelChange={(
          newRowSelectionModel: GridRowSelectionModel,
        ) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        columns={columns}
        rows={rows}
        rowCount={meteringPoints.totalElements}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        paginationMode="server"
        pageSizeOptions={[15, 25, 50, 100]}
      />
    </Box>
  );
};

export default MeasurementPoints;
