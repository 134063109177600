import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { ProtectedPaths } from "routes";
import MainLayout from "layouts/Main";
import { Box, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
import {
  fetchAllClients,
  fetchAllOperators,
  fetchReportsQueryOptions,
} from "store/appSlice";
import { useAppDispatch } from "hooks";
import {
  getReadingsReport,
  getReadingsReportById,
  updateReadingsReportQuery,
} from "api/resources/reports";
import ReportFilters from "../../ReportFilters";
import { MeteringPoint } from "api/models/MeteringPoint";
import { ReportsQuery } from "api/models/Reports";
import downloadCSV from "helpers/excelExport";
import {
  ReportsFormValues,
  SelectedClients,
  SelectedMeteringPoints,
} from "api/models/ReportsFormValues";
import { getTabsByRoleReports } from "../../../../../helpers/tabs";

const Reports = () => {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState<ReportsFormValues>({
    name: "",
    startDate: "",
    endDate: "",
    period: "",
    readingsType: "",
    sumColumns: 0,
    selectedOperator: 1,
    selectedClients: [],
    selectedMeteringPointsOptions: [],
    contractStartDate: "",
    contractEndDate: "",
  });
  const [reportQuery, setReportQuery] = useState<ReportsQuery>(null);
  const [loading, setLoading] = useState(false);
  const [queryLoading, setQueryLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(null);

  const dispatch = useAppDispatch();
  const { queryId } = useParams();

  useEffect(() => {
    dispatch(fetchReportsQueryOptions());
    dispatch(fetchAllClients(0, 100));
    dispatch(fetchAllOperators());

    if (queryId) {
      const queryIdNumber = parseInt(queryId, 10);
      if (!isNaN(queryIdNumber)) {
        getReadingsReportById(queryIdNumber).then((res) => {
          console.log({ res });
          // @ts-ignore
          setReportQuery(res.data);
        });
      } else {
        console.error("Invalid queryId:", queryId);
      }
    }
  }, []);

  const handleExportReport = () => {
    // @ts-ignore
    setQueryLoading(queryId);

    const fileName =
      formValues.startDate && formValues.endDate
        ? dayjs(formValues.startDate).format("DD.MM.YYYY") +
          "-" +
          dayjs(formValues.endDate).format("DD.MM.YYYY") +
          "_MEASURE"
        : "MEASURE";

    const selectedClients: number[] = [];
    const selectedMeteringPoints: number[] = [];

    formValues.selectedClients.map((sc) => {
      selectedClients.push(sc.value);
    });

    formValues.selectedMeteringPointsOptions.map((mp) => {
      selectedMeteringPoints.push(mp.value);
    });

    const postData = {
      name: formValues.name,
      startDate: formValues.startDate,
      endDate: formValues.endDate,
      readingsType: formValues.readingsType,
      readingsSumType: formValues.sumColumns,
      clients: selectedClients || [],
      meteringPoints: selectedMeteringPoints || [],
      contractStartDate: formValues.contractStartDate,
      contractEndDate: formValues.contractEndDate,
    };

    getReadingsReport(postData)
      .then((result) => {
        downloadCSV(result, fileName);
      })
      .then(() => {
        setQueryLoading(false);
        setSnackbar({
          type: "success",
          message: "Readings exported successfully",
        });
      })
      .catch((error) => {
        console.log({ error });
        setSnackbar({
          type: "error",
          message: error.message,
        });
        setQueryLoading(false);
      });
  };

  useEffect(() => {
    if (reportQuery) {
      const newFormValues = { ...formValues };

      const selectedClients: SelectedClients[] = [];
      const selectedMeteringPoints: SelectedMeteringPoints[] = [];

      reportQuery?.clients.map((sc) => {
        selectedClients.push({
          title:
            // @ts-ignore
            sc.type === "BUSINESS"
              ? sc.companyName
              : sc.firstName + " " + sc.lastName,
          value: sc.id,
        });
      });

      reportQuery?.meteringPoints.map((mp: MeteringPoint) => {
        selectedMeteringPoints.push({
          title: mp.eic,
          value: mp.id,
          address: mp.address,
        });
      });

      newFormValues.name = reportQuery.name;
      newFormValues.startDate = reportQuery.startDate;
      newFormValues.endDate = reportQuery.endDate;
      newFormValues.selectedMeteringPointsOptions =
        selectedMeteringPoints || [];
      newFormValues.contractStartDate = reportQuery.contractStartDate;
      newFormValues.contractEndDate = reportQuery.contractEndDate;
      newFormValues.readingsType = reportQuery.readingsType;
      newFormValues.sumColumns = reportQuery.readingsSumType;
      newFormValues.selectedClients = selectedClients || [];

      setFormValues(newFormValues);
    }
  }, [reportQuery]);

  const handleSaveQuery = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const selectedClients: number[] = [];
    const selectedMeteringPoints: number[] = [];

    formValues.selectedClients.map((sc) => {
      selectedClients.push(sc.value);
    });

    formValues.selectedMeteringPointsOptions.map((mp) => {
      selectedMeteringPoints.push(mp.value);
    });

    const postData = {
      name: formValues.name,
      startDate: formValues.startDate,
      endDate: formValues.endDate,
      readingsType: formValues.readingsType,
      readingsSumType: formValues.sumColumns,
      clients: selectedClients || [],
      meteringPoints: selectedMeteringPoints || [],
      contractStartDate: formValues.contractStartDate,
      contractEndDate: formValues.contractEndDate,
    };

    updateReadingsReportQuery(parseInt(queryId), postData)
      .then(() => {
        setLoading(false);
        setSnackbar({
          type: "success",
          message: "Query filters saved",
        });
      })
      .catch((error) => {
        console.log({ error });
        setLoading(false);
        setSnackbar({ type: "error", message: error.response?.data?.message });
      });
  };

  const snackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(null);
  };

  return (
    <MainLayout
      tabs={getTabsByRoleReports()}
      snackbar={{
        open: snackbar,
        handleClose: snackbarClose,
        type: snackbar?.type,
        message: snackbar?.message,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          sx={{ mr: "1rem" }}
          onClick={() => navigate(`/${ProtectedPaths.SavedQueries}`)}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" component="h5">
          Edit query
          <span style={{ color: grey[500] }}> - {reportQuery?.name}</span>
        </Typography>
      </Box>
      <ReportFilters
        formValues={formValues}
        setFormValues={setFormValues}
        submitBtnTitle="Save"
        onSubmit={handleSaveQuery}
        secondBtnTitle="Cancel"
        secondBtnAction={() => navigate(`/${ProtectedPaths.SavedQueries}`)}
        thirdBtnTitle="Run query"
        thirdBtnAction={() => handleExportReport()}
        thirdBtnLoading={queryLoading}
        editName={true}
        loading={false}
      />
    </MainLayout>
  );
};

export default Reports;
