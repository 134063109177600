import React, { useEffect, useRef, useState } from "react";
import Popper from "@mui/material/Popper";
import { deleteUser } from "api/resources/users";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectUsers } from "store/selectors";
import { fetchAllUsers } from "store/appSlice";
import {
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Modal,
  Paper,
  Typography,
  Link,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import Table from "components/Table";
import NewUserModal from "components/Modals/NewUser";
import EditUserModal from "components/Modals/EditUser";
import ConfirmationModal from "components/Modals/Confirmation";
import { PermissionTypes } from "helpers/enums";
import { ProtectedPaths } from "../../../../../routes";
import { LinkedClients, User } from "../../../../../api/models/User";

const UsersTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [editModal, setEditModal] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(null);

  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  const handleDeleteUser = (id: number) => {
    deleteUser(id)
      .then(() => {
        setConfirmationModal(null);
        dispatch(fetchAllUsers());
      })
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      accessorKey: "email",
      header: "Email",
      cell: (row: any) => {
        return (
          <Link href={`/${ProtectedPaths.Users}/${row.row.original.id}`}>
            {row.row.original.email}
          </Link>
        );
      },
    },
    {
      accessorKey: "firstName",
      header: "First name",
    },
    {
      accessorKey: "lastName",
      header: "Last name",
    },
    {
      accessorKey: "linkedClients",
      header: "User roles",
      cell: ({ row }: { row: { original: User } }) => {
        const linkedClients = row.original.linkedClients;
        return (
          <>
            {linkedClients.slice(0, 2).map((value: LinkedClients) => (
              <Link
                to={`/${ProtectedPaths.Clients}/${value.clientId}`}
                key={`UserRoleChip-${value.clientId}`}
              >
                <Chip
                  label={value.clientName}
                  size="small"
                  sx={{ mr: 1 }}
                  clickable
                />
              </Link>
            ))}
            {linkedClients.length > 2 && (
              <Chip
                label={`+ ${linkedClients.length - 2}`}
                size="small"
                sx={{ mr: 1 }}
              />
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Users</Typography>
        <Button
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          onClick={() => setOpenModal(true)}
        >
          Invite users
        </Button>
      </Box>
      <Table
        data={users}
        columns={columns}
        isFetching={users.length === 0}
        pageCount={1}
        page={setCurrentPage}
        searchLabel="Otsi kasutajat"
      />
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <NewUserModal
          requireClient={true}
          setOpenModal={setOpenModal}
          dispatch={() => dispatch(fetchAllUsers())}
        />
      </Modal>
      <Modal open={editModal} onClose={() => setEditModal(false)}>
        <EditUserModal
          user={editModal}
          setEditModal={setEditModal}
          dispatch={() => dispatch(fetchAllUsers())}
        />
      </Modal>
      <Modal open={confirmationModal} onClose={() => setEditModal(false)}>
        <ConfirmationModal
          title={`Do you want to delete the user ${confirmationModal?.email}?`}
          subtitle="This will remove access to the system from this user. This action cannot be undone."
          buttonTitle="Delete"
          action={() => handleDeleteUser(confirmationModal.id)}
          setConfirmationModal={setConfirmationModal}
        />
      </Modal>
    </>
  );
};

export default UsersTable;
