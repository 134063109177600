export const getInitials = (value: string) => {
  const matches = value.match(/(^|[\s\W])([A-ZÄÖÜÕ])/g);
  return matches
    ? matches
        .map((m) => m.trim())
        .slice(0, 2)
        .join("")
    : "";
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};
