import { Navigate } from "react-router-dom";
import { useAuth } from "provider/authProvider";
import { UnprotectedPaths } from "routes";
import React from "react";
import { useAppSelector } from "hooks";
import { selectMe } from "store/selectors";

type Props = { children: React.ReactNode };

export const UnProtectedRoute: React.FC<Props> = ({ children }) => {
  const { token } = useAuth();
  const me = useAppSelector(selectMe);

  // Check if the user is authenticated
  if (token && me.roles.length > 0) {
    // If authenticated, redirect to the readings page
    return <Navigate to={`/`} />;
  }

  if (token && me.roles.length === 0) {
    return <Navigate to={`/${UnprotectedPaths.Unauthorized}`} />;
  }

  // If authenticated, render the child routes
  return children;
};
