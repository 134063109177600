import React, { useState } from "react";
import MainLayout from "layouts/Main";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Forecast from "./Forecast";
import PriceLimits from "./PriceLimits";
import dayjs from "dayjs";
import { ProtectedPaths } from "../../../routes";

const SupplyPlans = () => {
  const navigate = useNavigate();
  const { date } = useParams();

  const [selectedTab, setSelectedTab] = useState(0);
  const [dateFilter, setDateFilter] = useState(date ? dayjs(date) : null);
  const [snackbar, setSnackbar] = useState(null);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleDateChange = (newValue: any) => {
    const formattedDate = newValue ? dayjs(newValue).format("YYYY-MM-DD") : "";
    setDateFilter(dayjs(formattedDate));
    navigate(
      `/${ProtectedPaths.SupplyPlans}/${dayjs(newValue).format("YYYY-MM-DD")}`,
      { replace: true },
    );
  };

  const snackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(null);
  };

  return (
    <MainLayout
      snackbar={{
        open: snackbar,
        handleClose: snackbarClose,
        type: snackbar?.type,
        message: snackbar?.message,
      }}
    >
      <Typography variant="h5">Supply plan</Typography>
      <DatePicker
        label={"Select date"}
        sx={{ background: "white", mt: "1.2rem" }}
        slotProps={{
          textField: { size: "small" },
          clearButton: { size: "small" },
        }}
        value={dateFilter || null}
        onChange={(newValue) => {
          handleDateChange(newValue);
        }}
      />
      {dateFilter && dayjs(dateFilter).isAfter(dayjs(new Date())) && (
        <Box>
          <Box sx={{ mt: 4, mb: 4, borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={selectedTab} onChange={handleTabChange}>
              <Tab label="Forecast" />
              <Tab label="Price limits" />
              <Tab label="Base load" disabled />
              <Tab label="Scheduled supply plans" disabled />
            </Tabs>
          </Box>
          {selectedTab === 0 && (
            <Box>
              <Forecast date={dateFilter} setSnackbar={setSnackbar} />
            </Box>
          )}
          {selectedTab === 1 && (
            <Box>
              <PriceLimits setSnackbar={setSnackbar} />
            </Box>
          )}
        </Box>
      )}
    </MainLayout>
  );
};

export default SupplyPlans;
