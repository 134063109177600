import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import MainLayout from "layouts/Main";
import { fetchInvites, fetchMe } from "store/appSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import { useAuth } from "provider/authProvider";
import { useNavigate } from "react-router-dom";
import { selectMe } from "store/selectors";
import { UnprotectedPaths } from "../../../routes";

const Unauthorized = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token } = useAuth();
  const me = useAppSelector(selectMe);

  useEffect(() => {
    if (token) {
      dispatch(fetchMe());
      dispatch(fetchInvites());
    } else {
      navigate(`/${UnprotectedPaths.Login}`);
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (token && me && me.roles.length > 0) {
      navigate(`/`);
    }
  }, [token, me, navigate]);

  return (
    <MainLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Typography variant="h6" component="p" gutterBottom>
          You have no relations to any companies.
        </Typography>
        <Typography variant="h6" component="p" gutterBottom>
          If you require assistance, please contact{" "}
          <a href="mailto:info@nordicpm.eu">info@nordicpm.eu</a>
        </Typography>
      </Box>
    </MainLayout>
  );
};

export default Unauthorized;
