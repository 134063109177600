import MainLayout from "layouts/Main";
import ClientsTable from "./ClientsTable";
import { getTabsByRoleRegistry } from "../../../../helpers/tabs";

const Clients = () => {
  return (
    <MainLayout tabs={getTabsByRoleRegistry()}>
      <ClientsTable />
    </MainLayout>
  );
};

export default Clients;
