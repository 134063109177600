import { useAppSelector } from "../hooks";
import { selectMe } from "../store/selectors";

export const getTabsByRoleRegistry = () => {
  const me = useAppSelector(selectMe);
  const role = me?.roles.find((d) => d.isCurrentRole)?.name;

  switch (role) {
    case "ROLE_ADMIN":
      return [
        { label: "Clients", path: "Clients" },
        { label: "Metering points", path: "MeteringPoints" },
        { label: "Contracts", path: "ClientContracts" },
        { label: "Production parks", path: "ProductionParks" },
      ];
    default:
      return [
        { label: "Metering points", path: "MeteringPoints" },
        { label: "Contracts", path: "ClientContracts" },
        { label: "Production parks", path: "ProductionParks" },
      ];
  }
};

export const getTabsByRoleCompany = () => {
  const me = useAppSelector(selectMe);
  const role = me?.roles.find((d) => d.isCurrentRole)?.name;

  return (
    role === "ROLE_ADMIN" && [
      { label: "Clients", path: "Clients" },
      { label: "Metering points", path: "MeteringPoints" },
      { label: "Contracts", path: "ClientContracts" },
      { label: "Production parks", path: "ProductionParks" },
    ]
  );
};

export const getTabsByRoleReports = () => {
  const me = useAppSelector(selectMe);
  const role = me?.roles.find((d) => d.isCurrentRole)?.name;

  switch (role) {
    case "ROLE_ADMIN":
      return [
        { label: "Readings", path: "Readings" },
        { label: "Saved queries", path: "SavedQueries" },
      ];
    default:
      return [{ label: "Readings", path: "Readings" }];
  }
};
