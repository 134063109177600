import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Table from "components/Table";
import { Link } from "react-router-dom";
import { fetchAllMeteringPoints } from "store/appSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectMe, selectMeteringPoints } from "store/selectors";
import { MeteringPointTypes } from "helpers/enums";
import { ProtectedPaths } from "routes";
import FilterComponent from "components/FilterComponent/FilterComponent";
import { MeteringPoint } from "api/models/MeteringPoint";
import { Client } from "api/models/Client";
import dayjs from "dayjs";

import {
  meteringPointAdminColumns,
  meteringPointAdminColumnsPlaceholders,
  meteringPointUserColumns,
  meteringPointUserColumnsPlaceholers,
} from "../meteringPointColumns";
import { getClientInfoFromRoles } from "../../../../../helpers/clientInfoFromRoles";

interface RowType {
  row: {
    original: MeteringPoint;
  };
}

const ClientsTable = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [resetPage, setResetPage] = useState(false);

  const dispatch = useAppDispatch();
  const meteringPoints = useAppSelector(selectMeteringPoints);
  const me = useAppSelector(selectMe);

  useEffect(() => {
    if (me) {
      const { hasClientRole, clientName } = getClientInfoFromRoles(me.roles);
      if (hasClientRole && clientName) {
        setFilters((prevFilters) => ({ ...prevFilters, client: clientName }));
      }
      dispatch(
        fetchAllMeteringPoints(
          currentPage,
          25,
          hasClientRole && clientName
            ? { ...filters, client: clientName }
            : filters,
        ),
      );
    }
  }, [currentPage, me]);

  const handleFilter = (newFilters: { [key: string]: string }) => {
    if (me) {
      const { clientName } = getClientInfoFromRoles(me.roles);
      if (clientName) {
        newFilters.client = clientName;
      }
    }
    setFilters(newFilters);
    setCurrentPage(0);
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        setCurrentPage(0);
        setResetPage(true);
        dispatch(fetchAllMeteringPoints(currentPage, 25, filters));
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [filters, dispatch]);

  const getClientName = (client?: Client) => {
    if (!client) return "";
    if (client.firstName) return `${client.firstName} ${client.lastName}`;

    return client.companyName;
  };

  const columns = [
    {
      accessorKey: "eic",
      header: "EIC",
    },
    {
      accessorKey: "client",
      header: "Client",
      cell: (row: RowType) => {
        return (
          <Link to={`/${ProtectedPaths.Clients}/${row.row.original.client.id}`}>
            {getClientName(row.row.original.client)}
          </Link>
        );
      },
    },
    {
      accessorKey: "address",
      header: "Address",
    },
    {
      accessorKey: "city",
      header: "City",
    },
    {
      accessorKey: "connectionState",
      header: "Status",
      cell: (row: RowType) => {
        return MeteringPointTypes[
          row.row.original.connectionState ? "CONNECTED" : "DISCONNECTED"
        ];
      },
    },
    {
      accessorKey: "consumptionScale",
      header: "Scale",
      cell: (row: RowType) => {
        return MeteringPointTypes[
          row.row.original.consumptionScale as keyof typeof MeteringPointTypes
        ];
      },
    },
    {
      accessorKey: "meteringType",
      header: "Type",
      cell: (row: RowType) => {
        return MeteringPointTypes[
          row.row.original.meteringType ? "REMOTE_READING" : "ONE"
        ];
      },
    },
    {
      accessorKey: "isBorderPoint",
      header: "Border",
      cell: (row: RowType) => {
        return MeteringPointTypes[
          row.row.original.isBorderPoint ? "true" : "false"
        ];
      },
    },
    {
      accessorKey: "operatorEic",
      header: "Operator",
    },
    {
      accessorKey: "lastReadingsSyncedAt",
      header: "Last sync",
      cell: (row: RowType) => {
        return row.row.original.lastReadingsSyncedAt
          ? dayjs(row.row.original.lastReadingsSyncedAt).format(
              "DD.MM.YYYY HH:mm",
            )
          : "";
      },
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Metering points</Typography>
      </Box>
      <FilterComponent
        columns={
          me && getClientInfoFromRoles(me.roles).isAdmin
            ? meteringPointAdminColumns
            : meteringPointUserColumns
        }
        columnPlaceholders={
          me && getClientInfoFromRoles(me.roles).isAdmin
            ? meteringPointAdminColumnsPlaceholders
            : meteringPointUserColumnsPlaceholers
        }
        onFilter={handleFilter}
      />
      <Table
        data={meteringPoints?.content}
        isFetching={!meteringPoints}
        columns={columns}
        pageCount={meteringPoints?.totalPages}
        page={setCurrentPage}
        resetPage={resetPage}
        searchLabel="Search for metering point"
      />
    </>
  );
};

export default ClientsTable;
