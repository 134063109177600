import axiosClient from "api/axiosInstance";
import { Contract } from "api/models/Contract";
import { Contracts } from "api/models/Contracts";
import { GridRowId } from "@mui/x-data-grid";

export const getContracts = async (
  page: number,
  size: number,
  filters: { [key: string]: string },
): Promise<Contracts> => {
  const client = axiosClient();

  const queryString = Object.keys(filters)
    .filter((key) => !!filters[key])
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`,
    )
    .join("&");

  const response = await client.get(
    `/contracts?page=${page}&size=${size}&${queryString}`,
  );

  return response.data;
};

export const sendContract = async (
  data: { firstDate: string; meteringPoints: GridRowId[]; lastDate: string },
  clientId: number,
): Promise<Contract> => {
  const client = axiosClient();

  const response = await client.post(
    `/clients/${clientId}/send-contract`,
    data,
  );

  return response.data;
};

export const updateContractsEndDate = async (data: {
  meteringPoints: GridRowId[];
  endDate: string;
}): Promise<Contract> => {
  const client = axiosClient();

  const response = await client.put("/contracts/update", data);

  return response.data;
};

export const fetchExportContracts = async (filters: {
  [key: string]: string;
}): Promise<void> => {
  const client = axiosClient();
  const queryString = Object.keys(filters)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`,
    )
    .join("&");
  const response = await client.get(`/contracts/export?${queryString}`, {
    responseType: "blob",
  });
  return response.data;
};
