import { useState } from "react";
import MainLayout from "layouts/Main";
import Typography from "@mui/material/Typography";
import SavedQueriesTable from "./SavedQueriesTable";
import { getTabsByRoleReports } from "../../../../helpers/tabs";

const Reports = () => {
  const [snackbar, setSnackbar] = useState(null);

  const snackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(null);
  };

  return (
    <MainLayout
      tabs={getTabsByRoleReports()}
      snackbar={{
        open: snackbar,
        handleClose: snackbarClose,
        type: snackbar?.type,
        message: snackbar?.message,
      }}
    >
      <Typography variant="h5">Saved queries</Typography>
      <SavedQueriesTable setSnackbar={setSnackbar} />
    </MainLayout>
  );
};

export default Reports;
