import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { ProtectedPaths } from "routes";
import { TabModel } from "api/models/TabModel";
import { useAppSelector } from "../../hooks";
import { selectMe } from "../../store/selectors";

interface StyledTabProps {
  label: string;
  onClick: () => void;
  disableRipple?: boolean;
}

interface TabsMenuProps {
  currentPath: string;
  tabs: TabModel[];
  isAdmin: boolean;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} onClick={props.onClick} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(14),
  marginRight: theme.spacing(2),
  padding: "0",
  color: "black",
  "&.Mui-selected": {
    color: "black",
    background: "none",
  },
}));

const TabsMenu = ({ currentPath, tabs, isAdmin }: TabsMenuProps) => {
  const navigate = useNavigate();

  const currentValue = tabs.findIndex(
    (t) =>
      ProtectedPaths[t.path as keyof typeof ProtectedPaths] === currentPath,
  );

  const me = useAppSelector(selectMe);

  return (
    <Box
      sx={{
        backgroundColor: "#e8eaf652",
        width: "100%",
        borderBottom: "0.5px solid #e7e8f0",
      }}
    >
      <Container maxWidth="xl">
        <Tabs value={currentValue === -1 ? 0 : currentValue}>
          {tabs.map((tab: any) => (
            <StyledTab
              key={`TabMenuItem-${tab.label}`}
              label={tab.label}
              onClick={() =>
                navigate(
                  isAdmin
                    ? "/" +
                        ProtectedPaths[tab.path as keyof typeof ProtectedPaths]
                    : tab.path === "Clients"
                      ? `/registry/clients/${me.roles.find((d) => d.isCurrentRole).client.id}`
                      : "/" +
                        ProtectedPaths[tab.path as keyof typeof ProtectedPaths],
                )
              }
            />
          ))}
        </Tabs>
      </Container>
    </Box>
  );
};

export default TabsMenu;
