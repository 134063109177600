import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Container,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { useAppSelector } from "hooks";
import { selectMe } from "store/selectors";
import { ProtectedPaths, UnprotectedPaths } from "routes";
import { indigo } from "@mui/material/colors"; // @ts-ignore
import Logo from "assets/images/logo.svg?react";
import TabsMenu from "./TabsMenu";
import { TabModel } from "api/models/TabModel";
import AvatarButton from "./AvatarButton";
import Dropdown from "./Dropdown";
import NotificationsBell from "./NotificationsBell";

export type MenuItemType =
  | { label: string; path: ProtectedPaths | string; submenu?: undefined }
  | {
      label: string;
      submenu: { label: string; path: ProtectedPaths }[];
      path?: undefined;
    };

interface HeaderProps {
  tabs: TabModel[];
}

const Header = ({ tabs }: HeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.replace("/", "");

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const me = useAppSelector(selectMe);

  useEffect(() => {
    if (me?.roles) {
      const isUserAdmin =
        me.roles.find((d) => d.isCurrentRole)?.name === "ROLE_ADMIN";
      setIsAdmin(isUserAdmin);
    }
  }, [me]);

  const clientId = me?.roles.find((d) => d.isCurrentRole)?.client?.id;

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElForecasts, setAnchorElForecasts] =
    useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenForecastsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElForecasts(event.currentTarget);
  };

  const handleCloseNavMenu = () => {};
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseForecastsMenu = () => {
    setAnchorElForecasts(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    if (me && me.roles.length === 0) {
      navigate(`/${UnprotectedPaths.Unauthorized}`);
    }
  }, [me, navigate]);

  const adminMenuItems = [
    { label: "Reports", path: ProtectedPaths.Readings },
    {
      label: "Forecasts",
      submenu: [
        { label: "Production", path: ProtectedPaths.Production },
        { label: "Consumption", path: ProtectedPaths.Consumption },
      ],
    },
    {
      label: "Registers",
      path: ProtectedPaths.Clients,
    },
    {
      label: "Settings",
      path: ProtectedPaths.Users,
    },
  ];

  const clientMenuItems = [
    { label: "Company", path: `${ProtectedPaths.Clients}/${clientId}` },
    {
      label: "Supply plans",
      path: ProtectedPaths.SupplyPlans,
    },
    { label: "Registers", path: ProtectedPaths.MeteringPoints },
  ];

  const menuItems: MenuItemType[] =
    me && me.roles.length > 0
      ? me.roles.find((d) => d.isCurrentRole)?.name === "ROLE_ADMIN"
        ? adminMenuItems
        : clientMenuItems
      : [];

  const renderMenuItems = (items: MenuItemType[]) =>
    items.map((page) => (
      <MenuItem
        key={page.label}
        onClick={
          page.submenu
            ? handleOpenForecastsMenu
            : () => navigate(`/${page.path}`)
        }
        selected={currentPath === page.path}
      >
        <Typography textAlign="center">{page.label}</Typography>
        {page.submenu && (
          <Menu
            id="submenu-appbar"
            anchorEl={anchorElForecasts}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            keepMounted
            open={Boolean(anchorElForecasts)}
            onClose={handleCloseForecastsMenu}
            TransitionComponent={Fade}
          >
            {page.submenu.map((subPage) => (
              <MenuItem
                key={subPage.label}
                onClick={() => {
                  navigate(`/${subPage.path}`);
                  handleCloseForecastsMenu();
                }}
                selected={currentPath === subPage.path}
              >
                <Typography textAlign="center">{subPage.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        )}
      </MenuItem>
    ));

  return (
    <>
      <AppBar position="static" sx={{ bgcolor: indigo[900] }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ mr: 2 }}>
              <Logo />
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              ></IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                TransitionComponent={Fade}
                sx={{ display: { xs: "flex", md: "none" } }}
              ></Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {renderMenuItems(menuItems)}
            </Box>
            <NotificationsBell />
            {me && (
              <Box sx={{ flexGrow: 0 }}>
                <AvatarButton me={me} handleOpenUserMenu={handleOpenUserMenu} />
                <Dropdown
                  anchorElUser={anchorElUser}
                  handleCloseUserMenu={handleCloseUserMenu}
                  handleLogout={handleLogout}
                  roles={me.roles}
                  meId={me.id}
                  navigate={navigate}
                />
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      {tabs && (
        <TabsMenu currentPath={currentPath} isAdmin={isAdmin} tabs={tabs} />
      )}
    </>
  );
};

export default Header;
