import { Box, Avatar, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

interface CAvatarProps {
  active?: boolean;
  size?: string;
  children?: any;
  style?: any;
  extraAvatar?: any;
}

const CAvatar = ({
  active,
  size,
  style,
  extraAvatar,
  children,
}: CAvatarProps) => {
  const theme = useTheme();

  const sizes = {
    small: {
      fontSize: "12px",
      height: "32px",
      width: "32px;",
    },
    medium: {
      fontSize: "15px",
      height: "40px",
      width: "40px;",
    },
    large: {
      fontSize: "15px",
      height: "48px",
      width: "48px;",
    },
    extralarge: {
      fontSize: "20px",
      height: "70px",
      width: "70px;",
    },
  };

  return (
    <Box sx={{ position: "relative" }} style={style}>
      <Avatar
        sx={{
          backgroundColor: active ? theme.palette.primary.main : grey[200],
          fontSize: size ? sizes[size as keyof typeof sizes].fontSize : "12px",
          fontWeight: "500",
          height: size ? sizes[size as keyof typeof sizes].height : "32px",
          width: size ? sizes[size as keyof typeof sizes].width : "32px",
          color: active ? "white" : "black",
          position: "relative",
        }}
      >
        {children}
      </Avatar>
      {extraAvatar && (
        <Avatar
          sx={{
            position: "absolute",
            width: "16px",
            height: "16px",
            fontSize: "7px",
            bottom: "-3px",
            right: "-5px",
            backgroundColor: theme.palette.primary.main,
          }}
        >
          {extraAvatar.initials}
        </Avatar>
      )}
    </Box>
  );
};

export default CAvatar;
