import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "provider/authProvider";
import {
  Grid,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Box,
  Container,
  Typography,
  Alert,
  Link,
} from "@mui/material";
import { ProtectedPaths, UnprotectedPaths } from "routes";
//@ts-ignore
import Logo from "assets/images/logo-colored.svg?react";

export default function SignIn() {
  const { setToken } = useAuth();
  const navigate = useNavigate();
  const [formError, setFormError] = useState(null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const userData = {
      email: data.get("email"),
      password: data.get("password"),
    };

    axios
      .post(`${import.meta.env.VITE_API_PATH}/auth/login`, userData)
      .then((response) => {
        setToken(response.data.data.accessToken);
        navigate("/", { replace: true });
      })
      .catch((error) => {
        console.log(error);
        setFormError(error.response.data.message);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "80vh" }}
      >
        <Grid item xs={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 5,
            }}
          >
            <Logo />
          </Box>
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              sx={{ mb: 1, textAlign: "center" }}
            >
              Log in
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {formError && (
                <Box sx={{ mt: 2, mb: 2 }}>
                  <Alert variant="filled" severity="error">
                    {formError}
                  </Alert>
                </Box>
              )}
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Log in
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
