import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "provider/authProvider";
import { UnprotectedPaths } from "routes";
import React, { useEffect } from "react";
import { useAppDispatch } from "../hooks";
import { fetchInvites, fetchMe } from "../store/appSlice";
import RoleBasedRedirect from "./RoleBasedRedirect";

export const ProtectedRoute: React.FC = () => {
  const { token } = useAuth();
  const location = useLocation();
  const dispatch = useAppDispatch();

  // Check if the user is authenticated
  if (!token) {
    // If not authenticated, redirect to the login page
    return <Navigate to={`/${UnprotectedPaths.Login}`} />;
  }

  useEffect(() => {
    if (token) {
      dispatch(fetchMe());
      dispatch(fetchInvites());
    }
  }, [token, dispatch]);

  if (token && location.pathname === "/") {
    return <RoleBasedRedirect />;
  }

  // If authenticated, render the child routes
  return <Outlet />;
};
