import React, { useRef, useState } from "react";
import MainLayout from "layouts/Main";
import { Alert, Box, Button, Snackbar, Stack, Typography } from "@mui/material";
import ElevationTable from "./ElevationTable";
import { uploadNewCoefficients } from "api/resources/elevationCoefficients";

const ForecaSettings = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error",
  );
  const [fileChanged, setFileChanged] = useState(false);

  const handleImportClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        await uploadNewCoefficients(file);
        setSnackbarMessage("File uploaded successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setFileChanged(true);
      } catch (error) {
        console.error("Error uploading file:", error);
        setSnackbarMessage("Error uploading file");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <MainLayout
      tabs={[
        { label: "Users", path: "Users" },
        { label: "Operators", path: "Operators" },
        { label: "Litgrid", path: "Litgrid" },
        { label: "Foreca", path: "ForecaSettings" },
        { label: "Elevation coefficents", path: "ElevationCoefficents" },
      ]}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">Elevation coefficents</Typography>
        <Stack spacing={2} direction="row">
          <Button variant="outlined" onClick={handleImportClick}>
            Import
          </Button>
        </Stack>
      </Box>
      <Box display="flex" justifyContent="space-between" sx={{ mt: 4 }}>
        <Box sx={{ maxWidth: "100%" }}>
          <ElevationTable
            fileChanged={fileChanged}
            setFileChanged={setFileChanged}
          />
        </Box>
      </Box>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant={"filled"}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </MainLayout>
  );
};

export default ForecaSettings;
