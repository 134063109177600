import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { getCoefficients } from "api/resources/elevationCoefficients";
import { ElevationCoefficient } from "api/models/ElevationCoefficients";

interface ElevationTableProps {
  fileChanged: boolean;
  setFileChanged: React.Dispatch<React.SetStateAction<boolean>>;
}

const ElevationTable: React.FC<ElevationTableProps> = ({
  fileChanged,
  setFileChanged,
}) => {
  const [coefficients, setCoefficients] = useState<ElevationCoefficient[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCoefficients = () => {
      getCoefficients()
        .then((data) => {
          setCoefficients(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching coefficients:", error);
          setLoading(false);
        });
    };

    fetchCoefficients();
  }, []);

  useEffect(() => {
    if (fileChanged) {
      setLoading(true);
      const fetchCoefficients = () => {
        getCoefficients()
          .then((data) => {
            setCoefficients(data);
            setFileChanged(false);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching coefficients:", error);
            setLoading(false);
          });
      };

      fetchCoefficients();
    }
  }, [fileChanged, setFileChanged]);

  const columns = Array.from(Array(24).keys());

  const rows = useMemo(() => {
    return coefficients.map((coefficient) => ({
      date: coefficient.date,
      hours: columns.map(
        (col) =>
          coefficient[
            `hour${col.toString().padStart(2, "0")}` as keyof ElevationCoefficient
          ],
      ),
    }));
  }, [coefficients, columns]);

  return (
    <TableContainer component={Paper} sx={{ maxHeight: "1000px" }}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ bgcolor: "#eeeeee", borderBottom: "1px solid #a7a7a7" }}
            >
              Date
            </TableCell>
            {columns.map((col) => (
              <TableCell
                key={col}
                sx={{
                  bgcolor: "#eeeeee",
                  borderBottom: "1px solid #a7a7a7",
                }}
              >
                {dayjs().hour(col).minute(0).format("HH:mm")}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={columns.length + 1}>
                <Box
                  display="flex"
                  justifyContent="center"
                  height="100%"
                  sx={{ background: "white" }}
                >
                  <Typography component="p" fontSize="16px">
                    Loading...
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                sx={{ "&:hover": { background: "#efefef" } }}
              >
                <TableCell
                  sx={{
                    background: "#eee",
                    position: "sticky",
                    left: "0",
                    fontWeight: "500",
                    borderRight: "1px solid #a7a7a7",
                    display: "flex",
                    flexWrap: "nowrap",
                    whiteSpace: "nowrap",
                    width: "max-content",
                  }}
                >
                  {dayjs(row.date, "DD-MM").format("DD.MM")}
                </TableCell>
                {row.hours.map((hour, colIndex) => (
                  <TableCell key={colIndex}>
                    <Typography component="p" fontSize="12px">
                      {hour}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ElevationTable;
