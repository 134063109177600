import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useDropzone } from "react-dropzone";

interface FileUploadProps {
  uploadedFile: any;
  setUploadedFile: React.Dispatch<React.SetStateAction<any>>;
  handleUpload: () => void;
}

const FileUpload = ({
  uploadedFile,
  setUploadedFile,
  handleUpload,
}: FileUploadProps) => {
  const onDrop = (acceptedFiles: any) => {
    setUploadedFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
        ".csv",
        ".numbers",
      ],
    },
    maxFiles: 1,
  });

  return (
    <Box>
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed grey",
          padding: "20px",
          cursor: "pointer",
          backgroundColor: isDragActive ? grey[200] : grey[100],
          maxWidth: "700px",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography>Drop the file here</Typography>
        ) : (
          <Typography component="p">
            Drag and drop the file here, or click to select from computer
          </Typography>
        )}
      </Box>

      {uploadedFile && (
        <Box mt={2}>
          <Typography component="p">Uploaded file:</Typography>
          <Typography
            component="p"
            style={{
              marginTop: "0.4rem",
              backgroundColor: grey[100],
              maxWidth: "max-content",
              padding: "6px 12px",
            }}
          >
            {uploadedFile.name}
          </Typography>
        </Box>
      )}

      {uploadedFile && (
        <Button
          variant="contained"
          onClick={handleUpload}
          disabled={!uploadedFile}
          sx={{ mt: 2 }}
        >
          Upload file
        </Button>
      )}
    </Box>
  );
};

export default FileUpload;
