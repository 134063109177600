import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { getClients } from "api/resources/clients";
import TextField from "components/TextField";

interface ClientSelectProps {
  invite?: any;
  selectedClient?: { title: string; value: number };
  setSelectedClient?: any;
  rowIndex?: number;
  minWidth?: number;
  maxWidth?: number;
  label?: string;
  placeholder?: string;
}

const SingleClientSelect: React.FC<ClientSelectProps> = ({
  selectedClient,
  setSelectedClient,
  invite,
  rowIndex,
  minWidth = 480,
  maxWidth = 480,
  label = "Client",
  placeholder = "Search by client name",
}) => {
  const [searchStringClient, setSearchStringClient] = useState("");
  const [clients, setClients] = useState<{ title: string; value: number }[]>(
    [],
  );

  const handleSelectClient = (option: { title: string; value: number }) => {
    setSelectedClient(invite, rowIndex, option);
  };

  const handleSearchClient = () => {
    getClients(0, 15, null, searchStringClient).then((result) => {
      const clientsData = result?.data.content.map((c) => {
        const name =
          c.type === "BUSINESS"
            ? c.companyName
            : c.firstName + " " + c.lastName;
        return { title: name, value: c.id };
      });

      setClients(clientsData);
    });
  };

  useEffect(() => {
    const delayInputTimeout = setTimeout(() => {
      if (searchStringClient) {
        handleSearchClient();
      }
    }, 300);
    return () => clearTimeout(delayInputTimeout);
  }, [searchStringClient]);

  return (
    <Box sx={{ mr: "0.5rem" }}>
      <Autocomplete
        id="selectedClients"
        value={selectedClient}
        options={clients}
        getOptionLabel={(option) => option.title}
        size="small"
        onChange={(event, newValue) => handleSelectClient(newValue)}
        renderInput={(params) => (
          <TextField
            id="client"
            params={params}
            minWidth={minWidth}
            maxWidth={maxWidth}
            label={label}
            placeholder={placeholder}
            onChange={(e: any) => setSearchStringClient(e.target.value)}
          />
        )}
      />
    </Box>
  );
};

export default SingleClientSelect;
