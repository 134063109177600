export const adminContractColumns = [
  "meteringPoint",
  "client",
  "firstDate",
  "lastDate",
];

export const adminContractColumnsPlaceholders = [
  "Metering point",
  "Client",
  "Beginning of contract",
  "End of contract",
];

export const userContractColumns = ["meteringPoint", "firstDate", "lastDate"];

export const userContractColumnsPlaceholers = [
  "Metering point",
  "Beginning of contract",
  "End of contract",
];
