import MainLayout from "layouts/Main";
import MeteringPointsTable from "./MeteringPointsTable";
import { getTabsByRoleRegistry } from "../../../../helpers/tabs";

const MeteringPoints = () => {
  return (
    <MainLayout tabs={getTabsByRoleRegistry()}>
      <MeteringPointsTable />
    </MainLayout>
  );
};

export default MeteringPoints;
