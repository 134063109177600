import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { periodsInUTC1 } from "helpers/periods";
import dayjs, { Dayjs } from "dayjs";
import {
  downloadSupplyPlanForecastTemplate,
  saveSupplyPlanForecast,
  updateSupplyPlanForecast,
  uploadSupplyPlanForecast,
} from "api/resources/supplyPlan";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectSupplyPlanForecast } from "store/selectors";
import { fetchSupplyPlanForecast } from "store/appSlice";
import downloadCSV from "helpers/excelExport";
import FileUpload from "components/FileUpload";
import { hours } from "helpers/periods";

interface ForecastProps {
  date: Dayjs;
  setSnackbar: (snackbar: { type: string; message: string }) => void;
}

interface PeriodQuantity {
  date: string;
  period: string;
  hour: string;
  value: string;
  disabled?: boolean;
}

const Forecast = ({ date, setSnackbar }: ForecastProps) => {
  const [periodQuantities, setPeriodQuantities] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [valuesChanged, setValuesChanged] = useState(false);

  const dispatch = useAppDispatch();
  const supplyPlanForecast = useAppSelector(selectSupplyPlanForecast);

  useEffect(() => {
    dispatch(fetchSupplyPlanForecast(dayjs(date).format("DD-MM-YYYY")));
  }, [dispatch, date]);

  const generateTable = () => {
    const periodQuantitiesCopy: PeriodQuantity[] = [];

    periodsInUTC1.map((period, periodIndex) => {
      let periodDate = dayjs(date);

      if (periodIndex + 1 === periodsInUTC1.length) {
        periodDate = dayjs(date).add(1, "day");
      }

      periodQuantitiesCopy.push({
        date: dayjs(periodDate).format("DD.MM.YYYY"),
        period: period,
        hour: hours[periodIndex],
        value: supplyPlanForecast
          ? supplyPlanForecast?.values[hours[periodIndex]]
          : null,
        disabled: periodIndex === 0,
      });
    });

    setPeriodQuantities(periodQuantitiesCopy);
  };

  useEffect(() => {
    generateTable();
  }, [date, supplyPlanForecast]);

  const handleSaveForecast = () => {
    setValuesChanged(false);
    const values = {};

    periodQuantities.map((period, periodIndex) => {
      if (period.value) {
        values[hours[periodIndex]] = Number(period.value);
      }
    });

    const postData = {
      values: values,
    };

    if (supplyPlanForecast) {
      updateSupplyPlanForecast(dayjs(date).format("DD-MM-YYYY"), postData)
        .then(() =>
          setSnackbar({
            type: "success",
            message: "Forecast updated",
          }),
        )
        .catch((error) => {
          setSnackbar({
            type: "error",
            message: "Error saving forecast",
          });
          console.log(error);
        });
    } else {
      saveSupplyPlanForecast(dayjs(date).format("DD-MM-YYYY"), postData)
        .then(() =>
          setSnackbar({
            type: "success",
            message: "Forecast saved",
          }),
        )
        .catch((error) => {
          setSnackbar({
            type: "error",
            message: error.message,
          });
          console.log(error);
        });
    }
  };

  const handleDownloadForecastTemplate = () => {
    downloadSupplyPlanForecastTemplate(dayjs(date).format("DD-MM-YYYY")).then(
      (result) => {
        downloadCSV(result, `Forecast-${dayjs(date).format("DD-MM-YYYY")}`);
      },
    );
  };

  const handleUpload = () => {
    if (uploadedFile) {
      setUploading(true);

      uploadSupplyPlanForecast(dayjs(date).format("DD-MM-YYYY"), uploadedFile)
        .then(() => {
          setUploading(false);
          setUploadedFile(null);
          setSnackbar({
            type: "success",
            message: "Forecast uploaded",
          });
        })
        .catch((error) => {
          setUploading(false);
          setSnackbar({
            type: "error",
            message: error.message,
          });
          console.log({ error });
        });
    }
  };

  return (
    <Grid container columnSpacing={8} sx={{ mt: "1rem" }}>
      <Grid item sm={6}>
        <FileUpload
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
          handleUpload={handleUpload}
        />
        <Box
          sx={{
            mt: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {valuesChanged && (
            <Button
              sx={{ mt: 1 }}
              variant="contained"
              onClick={() => handleSaveForecast()}
            >
              Save
            </Button>
          )}
        </Box>
        <TableContainer component={Paper} sx={{ maxWidth: 380, mt: 2 }}>
          <Table sx={{ maxWidth: 380 }} size="small">
            <TableHead sx={{ bgcolor: "#eeeeee" }}>
              <TableRow>
                <TableCell>Period (UTC +2)</TableCell>
                <TableCell>Quantity (kWh)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {periodQuantities.map((row, rowIndex) => (
                <TableRow key={`Row-${row.date}-${row.hour}`}>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        component="p"
                        sx={{ color: grey[400], mr: 1 }}
                      >
                        {row.date}
                      </Typography>
                      <Typography component="p">{row.period}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <TextField
                      id="coefficient"
                      type="number"
                      variant="standard"
                      size="small"
                      value={row.value || ""}
                      onChange={(e) => {
                        const periodQuantitiesCopy: PeriodQuantity[] = [
                          ...periodQuantities,
                        ];
                        setValuesChanged(true);

                        periodQuantitiesCopy[rowIndex].value = e.target.value;

                        setPeriodQuantities(periodQuantitiesCopy);
                      }}
                      disabled={row.disabled}
                      sx={{ maxWidth: "100px" }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item sm={6}>
        <Typography component="p">
          {`Please insert your forecasted production quantities for the date
          (${dayjs(date).format("DD.MM.YYYY")}) in kilowatt-hours (kWh).`}
        </Typography>
        <Typography component="p" sx={{ mt: "1rem" }}>
          <strong>Notice:</strong> These quantities are taken into account for
          the next trading period.
        </Typography>
        <Typography component="p" sx={{ mt: "1rem" }}>
          You may upload quantities by file or manually from in the form.
        </Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleDownloadForecastTemplate()}
          sx={{ mt: 2 }}
        >
          Download template
        </Button>
      </Grid>
    </Grid>
  );
};

export default Forecast;
