import { useState } from "react";
import { Alert, Box, Button, Grid, TextField, Typography } from "@mui/material";
import { defaultStyle } from "components/Modals";
import { editUser } from "api/resources/users";
import { User } from "api/models/User";
import { FormErrorProps } from "../../../api/models/FormErrorProps";

type EditUserModalProps = {
  user: User;
  dispatch: any;
  setEditModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditUserModal = ({
  user,
  dispatch,
  setEditModal,
}: EditUserModalProps) => {
  const [formErrors, setFormErrors] = useState(null);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const userData: any = {
      email: data.get("email"),
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      ...(data.get("password") && {
        password: data.get("password"),
        passwordConfirmed: data.get("passwordConfirmed"),
      }),
    };

    editUser(user.id, userData)
      .then((_) => {
        setEditModal(false);
        dispatch();
      })
      .catch((error) => {
        console.log(error);
        setFormErrors(error.response.data.errors);
      });
  };

  return (
    <Box sx={defaultStyle}>
      <Typography id="modal-modal-title" variant="h5" component="h5">
        Edit user
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={2} sx={{ mt: "1rem", mb: "2rem" }}>
          <Grid item sm={6}>
            <TextField
              id="firstName"
              name="firstName"
              label="First name"
              size="small"
              required
              defaultValue={user.firstName}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              id="lastName"
              name="lastName"
              label="Last name"
              size="small"
              required
              defaultValue={user.lastName}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              id="email"
              name="email"
              label="Email"
              size="small"
              required
              defaultValue={user.email}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item sm={12} sx={{ mt: "1rem" }}>
            <Typography id="modal-modal-title" component="h5">
              To change the password, please enter a new password
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <TextField
              type="password"
              id="password"
              name="password"
              label="New password"
              size="small"
              required
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              type="password"
              id="passwordConfirmed"
              name="passwordConfirmed"
              label="Repeat new password"
              size="small"
              required
              sx={{ width: "100%" }}
            />
          </Grid>
          {formErrors && (
            <Grid item sm={12}>
              {formErrors.map(({ message }: FormErrorProps) => (
                <Alert variant="filled" severity="error" sx={{ mb: "0.4rem" }}>
                  {message}
                </Alert>
              ))}
            </Grid>
          )}
          <Grid item sm={12} sx={{ mt: "1rem" }}>
            <Button variant="contained" type="submit" sx={{ mr: "1rem" }}>
              Save
            </Button>
            <Button onClick={() => setEditModal(false)}>Cancel</Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default EditUserModal;
