import { Role } from "../api/models/User";

export const getClientInfoFromRoles = (roles: Role[]) => {
  const isAdmin = roles.some((role) => role.name === "ROLE_ADMIN");
  const hasClientRole = roles.some(
    (role) =>
      role.name === "ROLE_CLIENT_ADMIN" || role.name === "ROLE_CLIENT_USER",
  );

  const clientName = hasClientRole
    ? roles.find((d) => d.isCurrentRole)?.client?.name
    : null;

  return { isAdmin, hasClientRole, clientName };
};
