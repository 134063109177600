import React, { useEffect, useState } from "react";
import MainLayout from "../../../../../layouts/Main";
import { Box, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ProtectedPaths } from "routes";
import { createProductionPark } from "../../../../../api/resources/productionPark";
import { ProductionPark } from "../../../../../api/models/ProductionPark";
import { ProductionParkType } from "../../../../../api/models/enums/ProductionParkType";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProductionParkForm from "../Components/ProductionParkForm";
import { useProductionParkForm } from "../Hooks/useProductionParkForm";
import { getTabsByRoleRegistry } from "../../../../../helpers/tabs";
import { useAppSelector } from "../../../../../hooks";
import { selectMe } from "../../../../../store/selectors";

export interface ApiError {
  message: string;
  status: number;
  error: string;
  timestamp: string;
  path: string;
}

const AddProductionPark: React.FC = () => {
  const initialFormData: ProductionPark = {
    name: "",
    type: ProductionParkType.SOLAR,
    code: "",
    aiolosCode: "",
    location: "",
    priceArea: "",
    clientId: 0,
    productionParkComponents: [],
    meteringPoints: [],
  };

  const {
    formData,
    setFormData,
    selectedCountry,
    selectedClients,
    setSelectedClients,
    handleChange,
    handleRemoveComponent,
    handleAddComponent,
    handleCountrySelect,
  } = useProductionParkForm(initialFormData);

  const me = useAppSelector(selectMe);
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState(null);

  useEffect(() => {
    const clientRole = me?.roles.find(
      (role) =>
        role.name === "ROLE_CLIENT_ADMIN" || role.name === "ROLE_CLIENT_USER",
    );

    if (clientRole) {
      setSelectedClients([
        { title: clientRole.client.name, value: clientRole.client.id },
      ]);
    }
  }, [me, setSelectedClients]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const clientId = selectedClients[0]?.value || 0;
    const meteringPointIds = formData.meteringPoints
      .filter((point) => point !== null)
      .map((point) => point.id);

    const postData = {
      ...formData,
      clientId,
      meteringPoints: meteringPointIds,
    };

    createProductionPark(postData)
      .then((res) => {
        navigate(`/${ProtectedPaths.EditProductionParks}/${res.data?.id}`);
      })
      .catch((err) => {
        setSnackbar({
          type: "error",
          message: err.message,
        });
      });
  };

  const snackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(null);
  };

  return (
    <MainLayout
      tabs={getTabsByRoleRegistry()}
      snackbar={{
        open: snackbar,
        handleClose: snackbarClose,
        type: snackbar?.type,
        message: snackbar?.message,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", paddingBottom: 3 }}>
        <IconButton
          sx={{ mr: "1rem" }}
          onClick={() => navigate(`/${ProtectedPaths.ProductionParks}`)}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" component="h5">
          Add production park
        </Typography>
      </Box>
      <ProductionParkForm
        formData={formData}
        setFormData={setFormData}
        selectedCountry={selectedCountry}
        handleCountrySelect={handleCountrySelect}
        selectedClients={selectedClients}
        setSelectedClients={setSelectedClients}
        handleChange={handleChange}
        handleRemoveComponent={handleRemoveComponent}
        handleAddComponent={handleAddComponent}
        handleSubmit={handleSubmit}
        showDeleteButton={false}
      />
    </MainLayout>
  );
};

export default AddProductionPark;
