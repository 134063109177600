import React from "react";
import Header from "components/Header";
import { Alert, Container, Snackbar } from "@mui/material";
import { TabModel } from "../../api/models/TabModel";

type MainLayoutProps = {
  tabs?: TabModel[];
  children: string | JSX.Element | JSX.Element[];
  snackbar?: {
    open: boolean;
    handleClose: () => void;
    type?: any;
    message: string;
  };
};

const MainLayout = ({ tabs, children, snackbar }: MainLayoutProps) => {
  return (
    <>
      <Header tabs={tabs} />
      <Container maxWidth="xl" sx={{ mt: 6, mb: 6 }}>
        {children}
        {snackbar && (
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={snackbar.handleClose}
          >
            <Alert
              onClose={snackbar.handleClose}
              severity={snackbar.type}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        )}
      </Container>
    </>
  );
};

export default MainLayout;
