import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  Link,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { getInviteTokenData, registerUser } from "api/resources/invites";
//@ts-ignore
import Logo from "assets/images/logo-colored.svg?react";
import { UnprotectedPaths } from "routes";
import { InviteTokenData } from "api/models/Invite";
import { parseAxiosError } from "api/resources/helpers/util";

export default function SignUp() {
  const token = localStorage.getItem("token");
  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get("invite");
  const [inviteData, setInviteData] = useState<InviteTokenData>({
    invitedBy: "",
    email: "",
    clientName: "",
    roleName: "",
    accepted: null,
    inviteToken: "",
    inviteDate: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  function parseRole(inviteData: InviteTokenData): string {
    const { roleName } = inviteData;
    if (roleName === "ROLE_ADMIN" || roleName === "ROLE_CLIENT_ADMIN") {
      return "administrator";
    }
    return "user";
  }

  useEffect(() => {
    if (token) {
      navigate("/");
    } else if (inviteToken) {
      getInviteTokenData(inviteToken)
        .then((result) => {
          if (result) setInviteData(result);
        })
        .catch(() => navigate("/login"));
    } else {
      navigate("/login");
    }
  }, [navigate, token, inviteToken]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const postData = {
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      password: data.get("password"),
      passwordConfirmed: data.get("repeatPassword"),
    };

    registerUser(inviteToken, postData)
      .then(() => navigate("/login"))
      .catch((error) => {
        const errorMessage = parseAxiosError(error);
        setErrorMessage(errorMessage);
        setSnackbarOpen(true);
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "90vh" }}
      >
        <Grid item xs={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 5,
            }}
          >
            <Logo />
          </Box>
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5" sx={{ mb: 1 }}>
              Create an account
            </Typography>
            <Typography component="p" sx={{ textAlign: "center" }}>
              {inviteData.invitedBy} has invited you to join{" "}
              <strong>{inviteData.clientName}</strong> as an{" "}
              <strong>{parseRole(inviteData)}</strong>.
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 4 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    name="email"
                    value={inviteData.email}
                    disabled
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="First name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="Last name"
                    name="lastName"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="repeatPassword"
                    label="Repeat password"
                    type="password"
                    id="repeatPassword"
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Create account
              </Button>
              <Grid container justifyContent="center">
                <Grid item>
                  <Link href={UnprotectedPaths.Login} variant="body2">
                    Already have an account? Log in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          variant={"filled"}
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
