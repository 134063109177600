import MainLayout from "layouts/Main";
import ContractsTable from "./ContractsTable";
import { getTabsByRoleRegistry } from "../../../../helpers/tabs";

const Contracts = () => {
  return (
    <MainLayout tabs={getTabsByRoleRegistry()}>
      <ContractsTable />
    </MainLayout>
  );
};

export default Contracts;
