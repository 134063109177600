import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Box, Grid, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { getProductionForecastThirtyDayAbsoluteError } from "../../../../../api/resources/productionForecasts";
import { ProductionPrognoseThirtyDayAbsoluteError } from "../../../../../api/models/ProductionForecast";
import { indigo } from "@mui/material/colors";

interface AbsoluteErrorChartProps {
  forecastType: string;
}

const AbsoluteErrorChart = ({ forecastType }: AbsoluteErrorChartProps) => {
  const [chartData, setChartData] = useState<
    ProductionPrognoseThirtyDayAbsoluteError[] | null
  >(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [dataset, setDataset] = useState<
    {
      day: string;
      forecaError?: number;
      aiolosError: number;
      meteologicaError?: number;
      realProduction: number;
    }[]
  >([]);

  useEffect(() => {
    setLoading(true);
    getProductionForecastThirtyDayAbsoluteError(forecastType).then((res) => {
      setChartData(res.data);
      setLoading(false);
    });
  }, [forecastType]);

  useEffect(() => {
    const datasetCopy: {
      day: string;
      forecaError?: number;
      aiolosError: number;
      meteologicaError?: number;
      realProduction: number;
    }[] = [];

    if (chartData) {
      chartData.forEach((row) => {
        datasetCopy.push({
          day: dayjs(row.date).format("DD.MM"),
          forecaError:
            Math.round(
              (row.absoluteErrorForeca / 1000 + Number.EPSILON) * 100,
            ) / 100,
          aiolosError:
            Math.round(
              (row.absoluteErrorAiolos / 1000 + Number.EPSILON) * 100,
            ) / 100,
          ...(row.absoluteErrorMeteologica && {
            meteologicaError:
              Math.round(
                (row.absoluteErrorMeteologica / 1000 + Number.EPSILON) * 100,
              ) / 100,
          }),
          realProduction:
            Math.round((row.productionDataSum / 1000 + Number.EPSILON) * 100) /
            100,
        });
      });

      setDataset(datasetCopy);
    }
  }, [chartData]);

  const series = [
    {
      dataKey: "realProduction",
      label: "Real production",
      color: indigo[400],
    },
    {
      dataKey: "aiolosError",
      label: "Aiolos error",
      color: "#9ddba1",
    },
  ];

  if (forecastType === "WIND") {
    series.push({
      dataKey: "meteologicaError",
      label: "Meteologica error",
      color: "#f9f098",
    });
  }

  if (forecastType === "SOLAR") {
    series.push({
      dataKey: "forecaError",
      label: "Foreca error",
      color: "#d0a8ff",
    });
  }

  return (
    <Box sx={{ mt: "2rem" }}>
      <Grid container sx={{ mb: "1rem" }}>
        <Grid item xs={6} display="flex" alignItems="center">
          <Typography component="p" fontWeight={600}>
            Past forecasts absolute error
          </Typography>
        </Grid>
      </Grid>
      {loading && !chartData && <p>Loading...</p>}
      {chartData && dataset.length > 0 && (
        <BarChart
          xAxis={[{ scaleType: "band", dataKey: "day" }]}
          dataset={dataset}
          series={series}
          margin={{ left: 40, right: 0, top: 40, bottom: 80 }}
          slotProps={{
            legend: {
              position: {
                vertical: "bottom",
                horizontal: "middle",
              },
              itemMarkWidth: 15,
              itemMarkHeight: 2,
              markGap: 5,
              itemGap: 5,
            },
          }}
          sx={{
            "& .MuiChartsLegend-series text": { fontSize: "12px !important" },
            padding: "5px",
          }}
          width={500}
          height={300}
        />
      )}
    </Box>
  );
};

export default AbsoluteErrorChart;
