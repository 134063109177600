import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "layouts/Main";
import { Box, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  selectClient,
  selectClientMeteringPoints,
  selectMe,
} from "store/selectors";
import { fetchClient, fetchClientMeteringPoints } from "store/appSlice";
import { grey } from "@mui/material/colors";
import {
  getClientMeteringPoints,
  loadMeteringPoints,
  updateClient,
} from "api/resources/clients";
import { fetchMeteringPointReadings } from "api/resources/meteringPoints";
import { CountryType } from "api/models/CountryType";
import MeasurementGrid from "./Components/MeasurementGrid";
import ClientForm from "./Components/ClientForm";
import RelatedUsers from "./Components/RelatedUsers/RelatedUsers";
import Settings from "./Components/Settings";
import { getTabsByRoleCompany } from "helpers/tabs";
import CAvatar from "components/Avatar";
import { getInitials } from "helpers/users";

const EditClient = () => {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const initialCountry: CountryType = { code: "", label: "", phone: "" };

  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] =
    useState<CountryType>(initialCountry);
  const [enterContractModal, setEnterContractModal] = useState(false);
  const [editContractModal, setEditContractModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);

  const meteringPoints = useAppSelector(selectClientMeteringPoints);
  const dispatch = useAppDispatch();
  const client = useAppSelector(selectClient);
  const me = useAppSelector(selectMe);
  const isClientUser = me?.roles.some(
    (role) => role.name === "ROLE_CLIENT_USER",
  );
  const isAdmin = me?.roles.some((role) => role.name === "ROLE_ADMIN");

  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const snackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(null);
  };

  useEffect(() => {
    dispatch(fetchClient(clientId));
    dispatch(
      fetchClientMeteringPoints(
        clientId,
        paginationModel.page,
        paginationModel.pageSize,
      ),
    );
  }, [clientId, paginationModel]);

  const handleDispatchClient = () => {
    dispatch(fetchClient(clientId));
  };

  useEffect(() => {
    if (client) {
      setSelectedCountry(client.country);
    }
  }, [client]);

  const handleLoadMeteringPoints = () => {
    setLoading(true);
    loadMeteringPoints(clientId)
      .then(() => {
        dispatch(
          fetchClientMeteringPoints(
            clientId,
            paginationModel.page,
            paginationModel.pageSize,
          ),
        );
        setLoading(false);
        setSnackbar({
          open: true,
          message: "Metering points loaded",
          severity: "success",
        });
      })
      .catch((error) => {
        setLoading(false);
        setSnackbar({
          open: true,
          message: `Error loading metering points. Reason: ${error.response?.data?.message}`,
          severity: "error",
        });
      });
  };

  const handleLoadMeasurementData = (loadAll = false) => {
    if (loadAll) {
      let allMeteringPoints = [];
      const meteringPointIds: GridRowId[] = [];

      getClientMeteringPoints(clientId, 0, client.meteringPointsCount).then(
        (res) => {
          allMeteringPoints = res.data.content;

          allMeteringPoints.map((meteringPoint) => {
            meteringPointIds.push(meteringPoint.id);
          });

          const postData = {
            meteringPointIds,
          };

          fetchMeteringPointReadings(postData)
            .then((res) => {
              if (res.status === 200) {
                setSnackbar({
                  open: true,
                  message: "Readings data is loading. This could take a while.",
                  severity: "info",
                });
              }
            })
            .catch((error) => {
              setSnackbar({
                open: true,
                message: error.response?.data?.message,
                severity: "error",
              });
            });
        },
      );
    } else {
      const postData = {
        meteringPointIds: rowSelectionModel,
      };

      fetchMeteringPointReadings(postData)
        .then((res) => {
          if (res.status === 200) {
            setSnackbar({
              open: true,
              message:
                "Readings data is loading. This will take a couple of minutes.",
              severity: "info",
            });
          }
        })
        .catch((error) => {
          console.log({ error });
          setSnackbar({
            open: true,
            message: error.response?.data?.message,
            severity: "error",
          });
        });
    }
  };

  const handleSave = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const postData = {
      companyName: data.get("companyName"),
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      email: data.get("email"),
      phone: data.get("phone"),
      iban: data.get("iban"),
      bankName: data.get("bankName"),
      country: selectedCountry.label ? selectedCountry.label : "",
      state: data.get("state"),
      zipCode: data.get("postalCode"),
    };

    updateClient(clientId, postData)
      .then(() => {
        setSnackbar({
          message: "Contact information saved",
          severity: "success",
        });
      })
      .catch((error) => {
        console.log({ error });
        setSnackbar({
          message: error.response?.data?.message,
          severity: "error",
        });
      });
  };

  return (
    <MainLayout
      tabs={getTabsByRoleCompany()}
      snackbar={{
        open: !!snackbar,
        handleClose: snackbarClose,
        type: snackbar?.severity,
        message: snackbar?.message,
      }}
    >
      {isAdmin && (
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <IconButton
            sx={{ mr: "0.6rem" }}
            size="small"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography component="p">Back</Typography>
        </Box>
      )}
      {client && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CAvatar size="extralarge" style={{ marginRight: "1rem" }}>
            {client.companyName
              ? getInitials(client.companyName)
              : getInitials(client.firstName + " " + client.lastName)}
          </CAvatar>
          <Box>
            <Typography variant="h5" component="h5">
              {client.companyName
                ? client.companyName
                : client.firstName + " " + client.lastName}
            </Typography>
            <Typography
              component="p"
              sx={{ fontSize: "14px", color: grey[600] }}
            >
              {client.eic}
            </Typography>
          </Box>
        </Box>
      )}
      <Box sx={{ mt: 4, mb: 4, borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab label="Contact information" />
          <Tab label="Related users" />
          <Tab label="Metering points" />
          {isAdmin && <Tab label="Settings" />}
        </Tabs>
      </Box>
      {client && client.id && selectedTab === 0 && (
        <ClientForm
          client={client}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          handleSave={handleSave}
          disabled={isClientUser}
        />
      )}
      {client && client.id && selectedTab === 1 && (
        <RelatedUsers
          client={client}
          handleDispatchClient={handleDispatchClient}
          setSnackbar={setSnackbar}
          disabled={isClientUser}
        />
      )}
      {client && selectedTab === 2 && (
        <MeasurementGrid
          rowSelectionModel={rowSelectionModel}
          setRowSelectionModel={setRowSelectionModel}
          client={client}
          loading={loading}
          handleLoadMeasurementData={handleLoadMeasurementData}
          handleLoadMeteringPoints={handleLoadMeteringPoints}
          setConfirmationModal={setConfirmationModal}
          setEditContractModal={setEditContractModal}
          setEnterContractModal={setEnterContractModal}
          meteringPoints={meteringPoints}
          clientId={clientId}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          enterContractModal={enterContractModal}
          editContractModal={editContractModal}
          confirmationModal={confirmationModal}
          setSnackbar={setSnackbar}
          disabled={!isAdmin}
        />
      )}
      {client && client.id && selectedTab === 3 && <Settings />}
    </MainLayout>
  );
};

export default EditClient;
