import React, { useRef, useState } from "react";
import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import EmailIcon from "@mui/icons-material/Email";
import CAvatar from "components/Avatar";
import styles from "./RelatedUsers.module.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popper from "@mui/material/Popper";
import ConfirmationModal from "components/Modals/Confirmation";
import { removeUserRole } from "api/resources/userRoles";
import { deleteInvite } from "api/resources/invites";
import { Snackbar } from "types/snackbar";
import EditUserRoleModal from "components/Modals/EditUserRole";

interface UserCardProps {
  topText: string;
  centerText: string;
  bottomText: string;
  initials?: string;
  isInvite?: boolean;
  user?: {
    roleId: number;
    roleName: string;
    user: {
      email: string;
      firstName: string;
      lastName: string;
      id: number;
    };
  };
  invite?: {
    accepted?: boolean;
    clientName: string;
    email: string;
    roleName: string;
    inviteDateTime: string;
    invitedBy: string;
    inviteTokenId: number;
  };
  clientId?: number;
  handleDispatchClient?: () => void;
  setSnackbar: React.Dispatch<React.SetStateAction<Snackbar>>;
  disabled?: boolean;
}

const UserCard: React.FC<UserCardProps> = ({
  topText,
  centerText,
  bottomText,
  initials,
  isInvite,
  user,
  invite,
  setSnackbar,
  clientId,
  handleDispatchClient,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [changeRoleModal, setChangeRoleModal] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleDeclineInvite = () => {
    deleteInvite(invite.inviteTokenId).then(() => {
      setSnackbar({
        open: true,
        message: "Invite removed",
        severity: "info",
      });
    });
  };

  const handleRemoveUser = () => {
    removeUserRole(user.roleId).then(() => {
      setSnackbar({
        open: true,
        message: "User removed",
        severity: "info",
      });
    });
  };

  return (
    <Box className={styles.relatedUser}>
      {!disabled && (
        <Box className={styles.moreIcon}>
          <IconButton
            size="small"
            ref={anchorRef}
            id="composition-button"
            aria-controls={open ? "composition-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <MoreVertIcon />
          </IconButton>

          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom"
            transition
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: "center center",
                }}
              >
                <Paper sx={{ background: "white", zIndex: 9999 }}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      {!isInvite && (
                        <MenuItem onClick={() => setChangeRoleModal(true)}>
                          Change role
                        </MenuItem>
                      )}
                      <MenuItem onClick={() => setConfirmationModal(true)}>
                        Remove
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      )}
      <CAvatar size="large" style={{ marginRight: "1rem" }}>
        {isInvite ? <EmailIcon /> : initials}
      </CAvatar>
      <Box minWidth={150}>
        <Typography
          component="p"
          sx={{
            fontSize: "10px",
            lineHeight: "10px",
            fontWeight: "500",
            letterSpacing: "0.8px",
            color: grey[700],
            textTransform: "uppercase",
          }}
        >
          {topText}
        </Typography>
        <Typography
          component="p"
          noWrap
          sx={{ fontSize: "18px", lineHeight: "28px" }}
          className={styles.text}
        >
          {centerText}
        </Typography>
        <Typography
          component="p"
          sx={{
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "13px",
            color: grey[500],
          }}
        >
          {bottomText}
        </Typography>
      </Box>
      {confirmationModal && (
        <Modal
          open={confirmationModal}
          onClose={() => setConfirmationModal(false)}
        >
          <Box>
            <ConfirmationModal
              title={`Are you sure you want to remove ${isInvite ? "the invite for" : ""} ${centerText}?`}
              subtitle={
                isInvite
                  ? `They will no longer be able to accept the invitation to manage this company.`
                  : `This will remove ${centerText}'s access to the company, but not their account.`
              }
              buttonTitle="Remove"
              action={() => {
                if (isInvite) {
                  handleDeclineInvite();
                } else {
                  handleRemoveUser();
                }
                setConfirmationModal(false);
              }}
              setConfirmationModal={setConfirmationModal}
            />
          </Box>
        </Modal>
      )}
      <Modal open={changeRoleModal} onClose={() => setChangeRoleModal(false)}>
        <EditUserRoleModal
          setOpenModal={setChangeRoleModal}
          handleDispatchClient={handleDispatchClient}
          user={user}
          clientId={clientId}
        />
      </Modal>
    </Box>
  );
};

export default UserCard;
