import axiosClient from "api/axiosInstance";
import {
  ReportsOptionsData,
  ReportsQuery,
  ReportsQueryData,
} from "api/models/Reports";

export const getAllReportsQueries = async (): Promise<ReportsQueryData> => {
  const client = axiosClient();

  const response = await client.get(`/readings-queries`);

  return response.data;
};

export const saveReadingsReportQuery = async (
  data: any,
): Promise<ReportsQuery> => {
  const client = axiosClient();

  const response = await client.post(`/readings-queries/save`, data);

  return response.data;
};

export const updateReadingsReportQuery = async (
  queryId: number,
  data: any,
): Promise<ReportsQuery> => {
  const client = axiosClient();

  const response = await client.put(`/readings-queries/${queryId}`, data);

  return response.data; // Return the Blob on success
};

export const deleteReadingsReportQuery = async (queryId: number) => {
  const client = axiosClient();

  const response = await client.delete(`/readings-queries/${queryId}`);

  return response.data;
};

export const getReportsQueryOptions = async (): Promise<ReportsOptionsData> => {
  const client = axiosClient();

  const response = await client.get(`/readings-queries/options`);

  return response.data;
};

export const getReadingsReport = async (data: any): Promise<Blob> => {
  const client = axiosClient();

  try {
    const response = await client.post(`/readings-queries/export`, data, {
      responseType: "blob",
    });

    return response.data; // Return the Blob on success
  } catch (error: any) {
    if (error.response && error.response.data instanceof Blob) {
      const errorText = await error.response.data.text(); // Convert Blob to text
      const errorJson = JSON.parse(errorText); // Parse it as JSON
      throw new Error(errorJson.message); // Explicitly throw an error
    }
  }
};

export const getReadingsReportById = async (
  queryId: number,
): Promise<string> => {
  const client = axiosClient();

  const response = await client.get(`/readings-queries/${queryId}`);

  return response.data;
};

export const getReadingsReportByIdExport = async (
  queryId: number,
): Promise<string> => {
  const client = axiosClient();

  const response = await client.get(`/readings-queries/${queryId}/export`, {
    responseType: "blob",
  });

  return response.data;
};
