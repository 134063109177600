import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import MainLayout from "layouts/Main";
import Typography from "@mui/material/Typography";
import ReportFilters from "../ReportFilters";
import { Modal } from "@mui/material";
import { getReadingsReport } from "api/resources/reports";
import { fetchAllOperators, fetchReportsQueryOptions } from "store/appSlice";
import { useAppDispatch } from "hooks";
import downloadCSV from "helpers/excelExport";
import SaveQueryModal from "components/Modals/SaveQuery";
import { ReportsFormValues } from "../../../../api/models/ReportsFormValues";
import { getTabsByRoleReports } from "../../../../helpers/tabs";

const Reports = () => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [formValues, setFormValues] = useState<ReportsFormValues>({
    startDate: "",
    endDate: "",
    period: "",
    readingsType: null,
    sumColumns: 0,
    selectedOperator: 1,
    selectedClients: [],
    selectedMeteringPointsOptions: [],
    contractStartDate: "",
    contractEndDate: "",
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchReportsQueryOptions());
    dispatch(fetchAllOperators());
  }, [dispatch]);

  const handleExportReport = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const selectedClients = formValues.selectedClients.map((sc) => {
      return sc.value;
    });

    const selectedMeteringPoints = formValues.selectedMeteringPointsOptions.map(
      (mp) => {
        return mp.value;
      },
    );

    const postData = {
      startDate: formValues.startDate || null,
      endDate: formValues.endDate || null,
      meteringPoints: selectedMeteringPoints,
      contractStartDate: formValues.contractStartDate || null,
      contractEndDate: formValues.contractEndDate || null,
      readingsType: formValues.readingsType || null,
      readingsSumType: formValues.sumColumns || null,
      operatorId: formValues.selectedOperator,
      clients: selectedClients,
    };

    const fileName =
      formValues.startDate && formValues.endDate
        ? dayjs(formValues.startDate).format("DD.MM.YYYY") +
          "-" +
          dayjs(formValues.endDate).format("DD.MM.YYYY") +
          "_MEASURE"
        : "MEASURE";

    getReadingsReport(postData)
      .then((result) => {
        downloadCSV(result, fileName);
      })
      .then(() => {
        setLoading(false);
        setSnackbar({
          type: "success",
          message: "Readings exported successfully",
        });
      })
      .catch((error) => {
        console.log({ error });
        setSnackbar({
          type: "error",
          message: error.message,
        });
        setLoading(false);
      });
  };

  const snackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(null);
  };

  return (
    <MainLayout
      tabs={getTabsByRoleReports()}
      snackbar={{
        open: snackbar,
        handleClose: snackbarClose,
        type: snackbar?.type,
        message: snackbar?.message,
      }}
    >
      <Typography variant="h5">Readings</Typography>
      <ReportFilters
        formValues={formValues}
        setFormValues={setFormValues}
        loading={loading}
        onSubmit={handleExportReport}
        submitBtnTitle="Run query"
        secondBtnAction={() => setOpenModal(true)}
        secondBtnTitle="Save query"
        editName={false}
      />
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <SaveQueryModal
          setOpenModal={setOpenModal}
          formValues={formValues}
          setSnackbar={setSnackbar}
        />
      </Modal>
    </MainLayout>
  );
};

export default Reports;
