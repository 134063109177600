import React, { useEffect, useState } from "react";
import { Box, Checkbox, Chip } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "components/TextField";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectClientMeteringPoints } from "store/selectors";
import { fetchClientMeteringPoints } from "store/appSlice";
import { ProductionPark } from "api/models/ProductionPark";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface MeteringPoint {
  address?: string;
  text?: string;
  id: number;
  eic: string;
}

interface MeteringPointsSelectProps {
  formValues: ProductionPark;
  setFormValues: React.Dispatch<React.SetStateAction<ProductionPark>>;
  clientId: number;
  disabled?: boolean;
}

const ProductionParkMeteringPoints = ({
  formValues,
  setFormValues,
  clientId,
  disabled,
}: MeteringPointsSelectProps) => {
  const dispatch = useAppDispatch();
  const meteringPoints = useAppSelector(selectClientMeteringPoints);
  const [meteringPointsOptions, setMeteringPointsOptions] = useState<
    MeteringPoint[]
  >([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  useEffect(() => {
    dispatch(fetchClientMeteringPoints(clientId.toString(), 0, 1000));
  }, [clientId, dispatch]);

  useEffect(() => {
    if (meteringPoints) {
      const options = meteringPoints.content.map((c) => ({
        id: c.id,
        eic: c.eic,
        address: c.address + ", " + c.city,
      }));
      setMeteringPointsOptions(options);
    }
  }, [meteringPoints]);

  const handleSelectMeteringPointsOption = (option: MeteringPoint) => {
    const isSelected = formValues.meteringPoints.some(
      (mp) => mp.id === option.id,
    );

    const updatedMeteringPoints = isSelected
      ? formValues.meteringPoints.filter((mp) => mp.id !== option.id)
      : [...formValues.meteringPoints, option];

    setFormValues({
      ...formValues,
      meteringPoints: updatedMeteringPoints,
    });
  };

  const handleSelectAllOptions = () => {
    setIsAllSelected(!isAllSelected);
    let updatedMeteringPoints: MeteringPoint[];

    if (isAllSelected) {
      updatedMeteringPoints = [];
    } else {
      updatedMeteringPoints = meteringPointsOptions;
    }

    setFormValues({
      ...formValues,
      meteringPoints: updatedMeteringPoints,
    });
  };

  const handleRemoveSelectedMeteringPoint = (id: number) => {
    const selectedMeteringPointsData = formValues.meteringPoints.filter(
      (mp) => mp.id !== id,
    );

    setFormValues({
      ...formValues,
      meteringPoints: selectedMeteringPointsData,
    });
  };

  return (
    <Box display="flex" alignItems="start" mb="0.5rem">
      <Box mr="0.5rem">
        <Autocomplete
          multiple
          id="selectedMeteringPoints"
          value={formValues.meteringPoints}
          limitTags={2}
          disableCloseOnSelect
          disabled={disabled}
          options={meteringPointsOptions}
          filterOptions={(options, params) => {
            const filter = createFilterOptions<MeteringPoint>({
              stringify: ({ eic, address }) => `${eic} ${address}`,
            });
            const filtered = filter(options, params);
            return [
              { id: 0, text: "Select all", eic: "Select all" },
              ...filtered,
            ];
          }}
          getOptionLabel={(option) => option.eic}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          defaultValue={[]}
          size="small"
          renderTags={(value, getTagProps) =>
            value?.map((option, index) => {
              const { key } = getTagProps({ index });
              return (
                <Chip
                  label={option.eic}
                  key={key}
                  onDelete={() => handleRemoveSelectedMeteringPoint(option.id)}
                  size="small"
                  sx={{ mr: "4px", mb: "4px" }}
                />
              );
            })
          }
          onChange={(_, newValue) => {
            if (newValue.length === 0) {
              setFormValues({
                ...formValues,
                meteringPoints: [],
              });
              setIsAllSelected(false);
            } else {
              setFormValues({
                ...formValues,
                meteringPoints: newValue,
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              id="client"
              params={params}
              minWidth={480}
              maxWidth={480}
              label="Metering points"
              placeholder="Search by metering point EIC"
            />
          )}
          renderOption={(props, option) => {
            const isSelected = formValues.meteringPoints.some(
              (mp) => mp.id === option.id,
            );

            return (
              <li
                {...props}
                style={{ padding: "0rem" }}
                onClick={() =>
                  option.text === "Select all"
                    ? handleSelectAllOptions()
                    : handleSelectMeteringPointsOption(option)
                }
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 4 }}
                  checked={
                    option.text === "Select all" ? isAllSelected : isSelected
                  }
                />
                {option.text
                  ? option.text
                  : `${option.eic} - ${option.address}`}
              </li>
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default ProductionParkMeteringPoints;
