export const periods = [
  "00:00 - 00:59",
  "01:00 - 01:59",
  "02:00 - 02:59",
  "03:00 - 03:59",
  "04:00 - 04:59",
  "05:00 - 05:59",
  "06:00 - 06:59",
  "07:00 - 07:59",
  "08:00 - 08:59",
  "09:00 - 09:59",
  "10:00 - 10:59",
  "11:00 - 11:59",
  "12:00 - 12:59",
  "13:00 - 13:59",
  "14:00 - 14:59",
  "15:00 - 15:59",
  "16:00 - 16:59",
  "17:00 - 17:59",
  "18:00 - 18:59",
  "19:00 - 19:59",
  "20:00 - 20:59",
  "21:00 - 21:59",
  "22:00 - 22:59",
  "23:00 - 23:59",
  "24:00 - 24:59",
];

export const periodsInUTC1 = [
  "00:00 - 00:59",
  "01:00 - 01:59",
  "02:00 - 02:59",
  "03:00 - 03:59",
  "04:00 - 04:59",
  "05:00 - 05:59",
  "06:00 - 06:59",
  "07:00 - 07:59",
  "08:00 - 08:59",
  "09:00 - 09:59",
  "10:00 - 10:59",
  "11:00 - 11:59",
  "12:00 - 12:59",
  "13:00 - 13:59",
  "14:00 - 14:59",
  "15:00 - 15:59",
  "16:00 - 16:59",
  "17:00 - 17:59",
  "18:00 - 18:59",
  "19:00 - 19:59",
  "20:00 - 20:59",
  "21:00 - 21:59",
  "22:00 - 22:59",
  "23:00 - 23:59",
  "00:00 - 00:59",
];

export const hours = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
  "24:00",
];
