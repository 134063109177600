import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import Select from "components/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

interface SettingsProps {}

const Settings: React.FC<SettingsProps> = () => {
  const options = [
    {
      label: "Total delivery",
      value: "totalDelivery",
    },
    {
      label: "Scheduled delivery",
      value: "scheduledDelivery",
    },
  ];

  const npOptions = [
    {
      label: "NPM otse",
      value: "npmOtse",
    },
    {
      label: "Eesti Pank",
      value: "eestiPank",
    },
  ];

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "2rem",
          }}
        >
          <Typography variant="h5" component="h5">
            Settings
          </Typography>
        </Box>
        <Box sx={{ mb: "2rem", maxWidth: "1200px" }}>
          <Divider textAlign="left" sx={{ mb: "1rem" }}>
            General
          </Divider>
          <Box sx={{ mb: 6 }}>
            <Select
              label="Contract type"
              id="contractType"
              name="contractType"
              value={"scheduledDelivery"}
              minWidth={400}
              options={options}
            />
          </Box>
          <Divider textAlign="left" sx={{ mb: "1rem" }}>
            Nord Pool
          </Divider>
          <Box sx={{ mb: 6 }}>
            <Select
              label="Portfolio"
              id="portfolio"
              name="portfolio"
              value={"npmOtse"}
              minWidth={400}
              options={npOptions}
            />
          </Box>
          <Divider textAlign="left" sx={{ mb: "1rem" }}>
            Balance plans
          </Divider>
          <Box>
            <FormControlLabel
              control={<Checkbox value="separate-client" color="primary" />}
              label="Separate client in balance plans"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Settings;
