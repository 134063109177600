import axiosClient from "api/axiosInstance";
import { Me } from "api/models/Me";

export const getMe = async (): Promise<Me> => {
  const client = axiosClient();

  const response = await client.get(`/auth/me`);

  return response.data;
};
