import React, { useEffect, useState } from "react";
import { Box, Checkbox, Chip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getClients } from "api/resources/clients";
import TextField from "components/TextField";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface ClientSelectProps {
  selectedClients?: { title: string; value: number }[];
  setSelectedClients?: (clients: { title: string; value: number }[]) => void;
  formValues?: any;
  setFormValues?: React.Dispatch<React.SetStateAction<any>>;
  minWidth?: number;
  maxWidth?: number;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
}

const ClientSelect: React.FC<ClientSelectProps> = ({
  selectedClients,
  setSelectedClients,
  formValues,
  setFormValues,
  minWidth = 480,
  maxWidth = 480,
  label = "Client",
  placeholder = "Search by client name",
  disabled,
}) => {
  const [searchStringClient, setSearchStringClient] = useState("");
  const [clients, setClients] = useState<{ title: string; value: number }[]>(
    [],
  );

  const handleSelectClient = (option: { title: string; value: number }) => {
    let selectedClientsData = [...selectedClients];

    if (!!selectedClients.find((sc) => sc.value === option.value)) {
      selectedClientsData = selectedClientsData.filter(
        (sc) => sc.value !== option.value,
      );
    } else {
      selectedClientsData = [...selectedClientsData, option];
    }

    setSelectedClients(selectedClientsData);
    if (setFormValues) {
      setFormValues({ ...formValues, selectedClients: selectedClientsData });
    }
  };

  const handleSearchClient = () => {
    getClients(0, 15, null, searchStringClient).then((result) => {
      const clientsData = result?.data.content.map((c) => {
        const name =
          c.type === "BUSINESS"
            ? c.companyName
            : c.firstName + " " + c.lastName;
        return { title: name, value: c.id };
      });

      setClients(clientsData);
    });
  };

  useEffect(() => {
    const delayInputTimeout = setTimeout(() => {
      if (searchStringClient) {
        handleSearchClient();
      }
    }, 300);
    return () => clearTimeout(delayInputTimeout);
  }, [searchStringClient]);

  const handleRemoveSelectedClient = (option: {
    title: string;
    value: number;
  }) => {
    let selectedClientsData = [...selectedClients];
    selectedClientsData = selectedClientsData.filter(
      (sc) => sc.title !== option.title,
    );
    setSelectedClients(selectedClientsData);
    if (setFormValues) {
      setFormValues({ ...formValues, selectedClients: selectedClientsData });
    }
  };

  return (
    <Box sx={{ mr: "0.5rem" }}>
      <Autocomplete
        multiple
        id="selectedClients"
        value={selectedClients}
        limitTags={2}
        disabled={disabled}
        disableCloseOnSelect
        renderTags={(value, getTagProps) =>
          value?.map((option, index) => {
            const { key } = getTagProps({ index });
            return (
              <Chip
                label={option.title}
                key={key}
                onDelete={() => handleRemoveSelectedClient(option)}
                size="small"
                sx={{ mr: "4px", mb: "4px" }}
              />
            );
          })
        }
        options={clients}
        getOptionLabel={(option) => option.title}
        defaultValue={[]}
        size="small"
        renderInput={(params) => (
          <TextField
            id="client"
            params={params}
            minWidth={minWidth}
            maxWidth={maxWidth}
            label={label}
            placeholder={placeholder}
            onChange={(e: any) => setSearchStringClient(e.target.value)}
          />
        )}
        renderOption={(props, option) => {
          const isSelected = !!selectedClients.find(
            (sc) => sc.value === option.value,
          );

          return (
            <li
              {...props}
              style={{ padding: "0rem" }}
              onClick={() => handleSelectClient(option)}
            >
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 4 }}
                checked={isSelected}
              />
              {option.title}
            </li>
          );
        }}
      />
    </Box>
  );
};

export default ClientSelect;
