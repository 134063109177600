import MainLayout from "layouts/Main";
import ProductionParkTable from "./ProductionParkTable";
import { getTabsByRoleRegistry } from "../../../../helpers/tabs";

const ProductionParks = () => {
  return (
    <MainLayout tabs={getTabsByRoleRegistry()}>
      <ProductionParkTable />
    </MainLayout>
  );
};

export default ProductionParks;
