import React from "react";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import { GlobalStyles } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AuthProvider from "provider/authProvider";
import { Provider } from "react-redux";
import { store } from "store";
import updateLocale from "dayjs/plugin/updateLocale";
import { indigo } from "@mui/material/colors";
import "dayjs/locale/en-gb";
import isTomorrow from "dayjs/plugin/isTomorrow"; // ES 2015
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
dayjs.extend(isTomorrow);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.updateLocale("en-gb", {
  weekStart: 1,
});
dayjs.extend(duration);

import Routes from "./routes";

const App = () => {
  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: indigo[700],
      },
    },
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: "#e8eaf652",
              color: "black",
            },
          },
        },
      },
    },
  });

  const globalStyles = (
    <GlobalStyles
      styles={() => ({
        html: {
          scrollbarGutter: "stable",
        },
      })}
    />
  );

  return (
    <React.StrictMode>
      <ThemeProvider theme={defaultTheme}>
        {globalStyles}
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <AuthProvider>
            <Provider store={store}>
              <Routes />
            </Provider>
          </AuthProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
