import React from "react";
import classNames from "classnames";
import forecaSymbols from "assets/images/foreca/forecaSymbols";
import styles from "../../ConsumptionForecasts/EditSample/ForecastDataGrid.module.scss";
import { grey } from "@mui/material/colors";
import {
  ForecastColumnType,
  ForecastType,
  ForecaSymbolKey,
  TableMatrixProduction,
} from "api/models/TableMatrix";
import { hardcodedPeriods } from "../ProductionPrognosisPeriods";
import { Link, Box, Checkbox } from "@mui/material";
import { handleToggleAllPeriods } from "./ProductionForecastService";
import { ProtectedPaths } from "../../../../../routes";

interface ProductionParkTableProps {
  tableMatrix: TableMatrixProduction[];
  selectedPriceArea: number;
  isEditable: boolean;
  isMouseDown: boolean;
  availableForecasts: { [key: string]: string[] };
  forecast: any;
  handleMouseDown: (event: any) => void;
  handleMouseUp: () => void;
  handleCellClick: (
    type: ForecastColumnType,
    parkIndex: number,
    rowIndex: number,
  ) => void;
  handleSetParkCorrection: (
    value: number,
    parkIndex: number,
    rowIndex: number,
  ) => void;
  setTableMatrix: (matrix: TableMatrixProduction[]) => void;
  overallValues: any[];
  setOverallValues: (values: any) => void;
}

const ProductionParkTable: React.FC<ProductionParkTableProps> = ({
  tableMatrix,
  selectedPriceArea,
  isEditable,
  isMouseDown,
  availableForecasts,
  forecast,
  handleMouseDown,
  handleMouseUp,
  handleCellClick,
  handleSetParkCorrection,
  setTableMatrix,
  overallValues,
  setOverallValues,
}) => {
  const isCheckboxChecked = (
    forecastType: ForecastType | "forecast",
    parkIndex: number,
  ) => {
    if (forecastType === "forecast") {
      return tableMatrix[selectedPriceArea].parks[parkIndex].readings
        .slice(0, 25)
        .every((reading) => reading.totalProductionSum.forecast.selected);
    }
    return tableMatrix[selectedPriceArea].parks[parkIndex].readings
      .slice(0, 25)
      .every((reading) => reading.totalProduction[forecastType]?.selected);
  };

  return (
    <div
      className={styles.forecastDataGrid}
      style={{ display: "flex", flexDirection: "row", overflowY: "hidden" }}
    >
      {tableMatrix[selectedPriceArea].parks.map((parkData, parkIndex) => (
        <table
          key={parkIndex}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          className={classNames(styles.table, isEditable && styles.isEditing)}
          style={{
            border: "solid",
            borderWidth: 1,
            borderTop: 0,
            borderBottom: 0,
            borderRight: 1,
            borderColor: grey[400],
            borderCollapse: "collapse",
            userSelect: "none",
          }}
        >
          <thead>
            <tr style={{ background: grey[200] }}>
              <th colSpan={100} style={{ textAlign: "left" }}>
                <Link
                  href={`/${ProtectedPaths.EditProductionParks}/${parkData.productionPark.id}`}
                >
                  {parkData.productionPark.name}
                </Link>
              </th>
            </tr>
            <tr>
              {availableForecasts[forecast.filtersJson[0].type].map(
                (forecastType: string) => (
                  <th
                    key={forecastType}
                    style={{ textTransform: "capitalize" }}
                  >
                    {forecastType}
                  </th>
                ),
              )}
              <th>Correction</th>
              <th>Forecast</th>
              {(forecast.filtersJson[0].type === "WIND" ||
                forecast.filtersJson[0].type === "HYBRID") && (
                <th>Wind info</th>
              )}
            </tr>
            <tr>
              {availableForecasts[forecast.filtersJson[0].type].map(
                (forecastType: string) => (
                  <th key={forecastType}>
                    <Box>
                      {isEditable && (
                        <Checkbox
                          size="small"
                          checked={isCheckboxChecked(
                            `${forecastType}Data` as ForecastType,
                            parkIndex,
                          )}
                          sx={{ m: 0, p: 0 }}
                          onClick={() => {
                            handleToggleAllPeriods(
                              tableMatrix,
                              setTableMatrix,
                              selectedPriceArea,
                              `${forecastType}Data` as ForecastType,
                              parkIndex,
                              overallValues,
                              setOverallValues,
                            );
                          }}
                        />
                      )}
                    </Box>
                  </th>
                ),
              )}
              <th></th>
              <th>
                <Box>
                  {isEditable && (
                    <Checkbox
                      size="small"
                      checked={isCheckboxChecked("forecast", parkIndex)}
                      sx={{ m: 0, p: 0 }}
                      onClick={() => {
                        handleToggleAllPeriods(
                          tableMatrix,
                          setTableMatrix,
                          selectedPriceArea,
                          "forecast",
                          parkIndex,
                          overallValues,
                          setOverallValues,
                        );
                      }}
                    />
                  )}
                </Box>
              </th>
              {(forecast.filtersJson[0].type === "WIND" ||
                forecast.filtersJson[0].type === "HYBRID") && <th></th>}
            </tr>
          </thead>
          <tbody>
            {tableMatrix.length > 0 &&
              hardcodedPeriods.map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {availableForecasts[forecast.filtersJson[0].type].map(
                    (forecastType: string) => {
                      const forecastKey = `${forecastType}Data` as ForecastType;
                      return (
                        <td
                          key={forecastType}
                          className={classNames(
                            styles.data,
                            forecastKey === "forecast"
                              ? parkData.readings[rowIndex].totalProductionSum
                                  .forecast.selected && styles.selected
                              : parkData.readings[rowIndex].totalProduction[
                                  forecastKey
                                ]?.selected && styles.selected,
                          )}
                          onMouseDown={() => {
                            handleCellClick(forecastKey, parkIndex, rowIndex);
                          }}
                          onMouseEnter={() => {
                            if (isMouseDown) {
                              handleCellClick(forecastKey, parkIndex, rowIndex);
                            }
                          }}
                        >
                          <span className={styles.cellContent}>
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {forecastKey === "forecast"
                                ? parkData.readings[rowIndex].totalProductionSum
                                    .forecast.value
                                : parkData.readings[rowIndex].totalProduction[
                                    forecastKey
                                  ]?.value}
                            </span>
                            {forecastType === "foreca" && (
                              <span className={styles.forecaSymbol}>
                                <img
                                  src={
                                    forecaSymbols[
                                      parkData.readings[rowIndex]
                                        .totalProduction
                                        .forecaSymbol as ForecaSymbolKey
                                    ]
                                  }
                                  alt={""}
                                />
                              </span>
                            )}
                          </span>
                        </td>
                      );
                    },
                  )}
                  <td className={`${styles.correction}`}>
                    <input
                      type="number"
                      className={styles.correctionInput}
                      onChange={(e) =>
                        handleSetParkCorrection(
                          parseFloat(e.target.value),
                          parkIndex,
                          rowIndex,
                        )
                      }
                      value={
                        tableMatrix[selectedPriceArea].parks[parkIndex]
                          .readings[rowIndex].totalProductionSum.adjustment
                      }
                      step="0.1"
                    />
                  </td>
                  <td
                    key="forecast"
                    className={classNames(
                      styles.data,
                      tableMatrix[selectedPriceArea].parks[parkIndex].readings[
                        rowIndex
                      ].totalProductionSum.forecast.selected && styles.selected,
                    )}
                    onMouseDown={() => {
                      handleCellClick("forecast", parkIndex, rowIndex);
                    }}
                    onMouseEnter={() => {
                      if (isMouseDown) {
                        handleCellClick("forecast", parkIndex, rowIndex);
                      }
                    }}
                  >
                    {tableMatrix[selectedPriceArea].parks[parkIndex].readings[
                      rowIndex
                    ].totalProductionSum.forecast.value.toFixed(2)}
                  </td>
                  {(forecast.filtersJson[0].type === "HYBRID" ||
                    forecast.filtersJson[0].type === "WIND") && (
                    <td style={{ pointerEvents: "none" }}>
                      {parkData.readings[rowIndex].totalProduction.forecaWind}
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      ))}
    </div>
  );
};

export default ProductionParkTable;
