import React from "react";
import { Navigate } from "react-router-dom";
import { ProtectedPaths, UnprotectedPaths } from "routes";
import { useAppSelector } from "../hooks";
import { selectMe } from "../store/selectors";

const RoleBasedRedirect: React.FC = () => {
  const me = useAppSelector(selectMe);

  const getCurrentRole = () => me?.roles.find((d) => d.isCurrentRole);

  if (me) {
    if (me.roles.length === 0) {
      return <Navigate to={`/${UnprotectedPaths.Unauthorized}`} />;
    }

    const currentRole = getCurrentRole();
    if (currentRole?.name === "ROLE_ADMIN") {
      return <Navigate to={`/${ProtectedPaths.Readings}`} />;
    } else if (currentRole) {
      return (
        <Navigate to={`/${ProtectedPaths.Clients}/${currentRole.client.id}`} />
      );
    }
  }

  return null;
};

export default RoleBasedRedirect;
