import axiosClient from "../axiosInstance";
import { CreateUserWithInvite, InviteTokenData } from "../models/Invite";

export const getInviteTokenData = async (
  token: string,
): Promise<InviteTokenData> => {
  const client = axiosClient();

  const response = await client.get(`/invite/get-invite-data/${token}`);

  return response.data.data;
};

export const registerUser = async (
  token: string,
  data: CreateUserWithInvite,
): Promise<void> => {
  const client = axiosClient();

  const response = await client.post(`/invite/register/${token}`, data);

  return response.data;
};

export const getUserInvites = async (): Promise<InviteTokenData[]> => {
  const client = axiosClient();

  const response = await client.get(`/user-roles/get-invites`);

  return response.data.data;
};

export const acceptInvite = async (inviteToken: string): Promise<void> => {
  const client = axiosClient();

  const response = await client.post(`/invite/register/${inviteToken}`);

  return response.data;
};

export const declineInvite = async (inviteToken: string): Promise<void> => {
  const client = axiosClient();

  const response = await client.post(`/invite/register/${inviteToken}/decline`);

  return response.data;
};

export const deleteInvite = async (inviteTokenId: number): Promise<void> => {
  const client = axiosClient();

  const response = await client.delete(`/invite/${inviteTokenId}`);

  return response.data;
};
