import { Box, IconButton, Typography } from "@mui/material";
import { indigo } from "@mui/material/colors";
import { RoleTypes } from "api/models/enums/UserRole";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CAvatar from "components/Avatar";
import { Me } from "api/models/Me";
import { getInitials } from "helpers/users";
import { Role } from "api/models/User";

interface AvatarButtonProps {
  me: Me;
  handleOpenUserMenu: any;
}

const AvatarButton = ({ me, handleOpenUserMenu }: AvatarButtonProps) => {
  const getClientName = () => {
    if (me.roles.length === 0) {
      return "";
    }
    const currentRole = me.roles.find((role: Role) => role.isCurrentRole);
    if (currentRole?.client) {
      return currentRole.client.name;
    } else {
      return RoleTypes[currentRole?.name as keyof typeof RoleTypes];
    }
  };

  return (
    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          border: `1px solid ${indigo}`,
          borderRadius: "2px",
          padding: "0.4rem 0.8rem",
          backgroundColor: "#222c83",
          transition: "100ms ease-in-out",
          "&:hover": {
            backgroundColor: "#283494",
          },
        }}
      >
        <CAvatar size="medium">
          {getInitials(me.firstName + " " + me.lastName)}
        </CAvatar>
        <Box sx={{ ml: "0.8rem" }}>
          <Typography
            sx={{
              color: "white",
              textAlign: "left",
              fontSize: "15px",
              lineHeight: "15px",
              fontWeight: "500",
              marginBottom: "2px",
            }}
          >
            {`${me.firstName} ${me.lastName}`}
          </Typography>
          <Typography
            sx={{
              color: "white",
              textAlign: "left",
              fontSize: "12px",
              lineHeight: "12px",
            }}
          >
            {getClientName()}
          </Typography>
        </Box>
        <ExpandMoreIcon sx={{ color: "white", ml: "0.8rem" }} />
      </Box>
    </IconButton>
  );
};

export default AvatarButton;
