import {
  Box,
  Divider,
  Fade,
  ListSubheader,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import CAvatar from "components/Avatar";
import WorkIcon from "@mui/icons-material/Work";
import CheckIcon from "@mui/icons-material/Check";
import { grey } from "@mui/material/colors";
import { Role } from "api/models/User";
import { RoleTypes } from "api/models/enums/UserRole";
import { switchUserRole } from "api/resources/userRoles";
import { getInitials } from "helpers/users";
import { ProtectedPaths } from "routes";
import { useAppSelector } from "../../hooks";
import { selectMe } from "../../store/selectors";

interface DropdownProps {
  anchorElUser: any;
  handleCloseUserMenu: any;
  handleLogout: any;
  roles: Role[];
  meId: number;
  navigate: any;
}

const Dropdown = ({
  anchorElUser,
  handleCloseUserMenu,
  handleLogout,
  roles,
  meId,
  navigate,
}: DropdownProps) => {
  const me = useAppSelector(selectMe);

  const handleSwitchRole = (roleId: number) => {
    switchUserRole(roleId).then(() => {
      navigate("/");
    });
  };

  const getAvatarContent = (role: Role) => {
    if (role.isCurrentRole)
      return <CheckIcon sx={{ width: "16px", height: "16px" }} />;
    if (role.client) return getInitials(role.client.name);

    return (
      <WorkIcon
        sx={{
          width: "16px",
          height: "16px",
          color: grey[700],
        }}
      />
    );
  };

  return (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
      TransitionComponent={Fade}
    >
      <MenuItem
        key={"Profile"}
        onClick={() => navigate(`/${ProtectedPaths.Users}/${meId}`)}
      >
        <Typography textAlign="center">Profile settings</Typography>
      </MenuItem>

      {!!me.roles.length && (
        <>
          <Divider />
          <ListSubheader
            sx={{
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "28px",
            }}
          >
            Choose role
          </ListSubheader>
        </>
      )}

      {roles.map((role: Role) => (
        <MenuItem
          key={`Role-${role.id}`}
          onClick={() => handleSwitchRole(Number(role.id))}
        >
          <Box
            sx={{
              padding: "8px 0",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <CAvatar
              active={role.isCurrentRole}
              size="small"
              style={{ marginRight: "10px" }}
            >
              {getAvatarContent(role)}
            </CAvatar>
            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "16px",
                  marginBottom: "4px",
                  fontWeight: role.isCurrentRole ? "500" : "400",
                }}
              >
                {role.client?.name || "Nordic PM"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px",
                  lineHeight: "11px",
                  letterSpacing: "0.6px",
                  fontWeight: "400",
                  color: grey[600],
                  textTransform: "uppercase",
                }}
              >
                {RoleTypes[role.name as keyof typeof RoleTypes]}
              </Typography>
            </Box>
          </Box>
        </MenuItem>
      ))}
      <Divider />
      <MenuItem key={"Log out"} onClick={handleLogout}>
        <Typography textAlign="center">Log out</Typography>
      </MenuItem>
    </Menu>
  );
};

export default Dropdown;
